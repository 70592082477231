import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import LoginPage from "@/pages/auth/login-page.vue";
import HomePage from "@/pages/home/home-page.vue";
import CredentialsPage from "@/pages/credentials/credentials-page.vue";
import ProfilePage from "@/pages/profile/profile-page.vue";
import RecoverPage from "@/pages/auth/recover-page.vue";
import RecoverHashPage from "@/pages/auth/recover-hash-page.vue";
import RegisterPage from "@/pages/auth/register-page.vue";
import ConcursePage from "@/pages/concurses/concurses-page.vue";
import ConcurseEditPage from "@/pages/concurses/concurses-edit.vue";
import ConcurseEvaluatePage from "@/pages/concurses/concurse-evaluate.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/recover",
    name: "RecoverPage",
    component: RecoverPage,
  },
  {
    path: "/recover/hash/:hash",
    name: "RecoverHashPage",
    component: RecoverHashPage,
  },
  {
    path: "/register",
    name: "RegisterPage",
    component: RegisterPage,
  },
  {
    path: "/home",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/credentials",
    name: "CredentialsPage",
    component: CredentialsPage,
  },
  {
    path: "/profile",
    name: "ProfilePage",
    component: ProfilePage,
  },
  {
    path: "/concurses",
    name: "ConcursePage",
    component: ConcursePage,
  },
  {
    path: "/concurses/:id",
    name: "ConcurseEditPage",
    component: ConcurseEditPage,
  },
  {
    path: "/concurses/evaluate/:id",
    name: "ConcurseEvaluatePage",
    component: ConcurseEvaluatePage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
