<script setup lang="ts">
import { ref, toRefs, computed, defineEmits, defineProps, withDefaults, watchEffect, watch } from "vue";

interface PropsTypes {
  value: string;
  type?: string;
  label?: string;
  background?: string;
  color?: string;
  prefix?: boolean;
  prefixClick?: boolean;
  sufix?: boolean;
  sufixClick?: boolean;
  border?: boolean;
  error?: boolean;
  errorMessage?: string;
  borderRadios?: string;
  borderColor?: string;
  min?: number
  max?: number
  minLength?: number
  maxLength?: number
  autoComplete?: string
  sanitize?: any
}

const emit = defineEmits(["update:value", "click:prefix", "click:sufix"]);

const props = withDefaults(defineProps<PropsTypes>(), {
  type: "text",
  prefix: false,
  prefixClick: false,
  sufix: false,
  sufixClick: false,
  border: true,
  error: false,
  background: "#e8e8e8",
  color: "#000",
  autoComplete: "off",
  borderRadios: "0.5rem",
  borderColor: "gray",
});

const {
  border,
  borderRadios,
  borderColor,
  prefixClick,
  sufixClick,
} = toRefs(props);

const styleBorder = computed(() =>
  border.value ? `1px solid ${borderColor.value}` : "1px solid transparent"
);

const cursorPrefix = computed(() => (prefixClick.value ? "pointer" : "auto"));
const cursorSufix = computed(() => (sufixClick.value ? "pointer" : "auto"));

function clickPrefix() {
  if (prefixClick.value) emit("click:prefix");
}

function clickSufix() {
  if (sufixClick.value) emit("click:sufix");
}

function updateValue(event: any) {
  let value = event?.target?.value;
  emit("update:value", value);

  if (props.sanitize) {
    setTimeout(() => {
      value = value.replace(props.sanitize, '');
      emit("update:value", value);
    }, 50)
  }

}
</script>
<template>
  <div class="base-input" :class="{ error: error }">
    <div v-if="label" class="box-label">{{ label }}</div>
    <div class="box-input">
      <div v-if="prefix" class="prefix">
        <div class="slot-content" @click="clickPrefix()">
          <slot name="prefix"></slot>
        </div>
      </div>
      <div class="form-input">
        <textarea
          :type="type"
          :value="value"
          :min="min"
          :max="max"
          :minLength="minLength"
          :maxLength="maxLength"
          @input="updateValue($event)"
          :autocomplete="autoComplete"
          v-bind="$attrs"
        />
      </div>
      <div v-if="sufix" class="sufix">
        <div class="slot-content" @click="clickSufix()">
          <slot name="sufix"></slot>
        </div>
      </div>
    </div>
    <div class="box-message">
      {{ error ? errorMessage : "" }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
$base-height: 12.75rem;
$border: v-bind("styleBorder");
$border-radius: v-bind("borderRadios");

.base-input {
  display: flex;
  width: 100%;
  flex-direction: column;

  .box-label {
    display: flex;
    font-size: 0.85rem;
    margin: 0.4rem 0.8rem;
  }

  .box-input {
    display: flex;
    width: 100%;
    flex-direction: row;
    border: $border;
    border-radius: $border-radius;
    background: v-bind("background");
    color: v-bind("color");
    padding-block: 0.5rem;

    .prefix,
    .sufix {
      display: flex;
      flex-direction: column;
      min-width: 2.5rem;
      max-width: 2.5rem;
      align-items: center;
      justify-content: center;

      .slot-content {
        display: flex;
        margin: 5px;
      }
    }

    .prefix {
      cursor: v-bind("cursorPrefix");
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      border-right: $border;
    }

    .sufix {
      cursor: v-bind("cursorSufix");
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-left: $border;
    }

    .form-input {
      flex: 1;
      padding-inline: 0.75rem;
      height: $base-height;

      textarea {
        width: 100%;
        height: $base-height;
        background: none;
        font-size: 1rem;
        outline: none;
        border: none;
      }
    }
  }
}

.base-input.error {
  .box-input {
    border: 1px solid var(--red);
  }
}

.box-message {
  height: 1.25rem;
  font-size: 0.75rem;
  text-align: end;
  padding-block-start: 0.2rem;
  margin-inline: 0.25rem;
  color: var(--red);
}
</style>
