import { get } from "lodash-es";
import { useI18n } from "vue-i18n";

export function getLanguage() {
  return (
    localStorage.getItem("locale") || process.env.VUE_APP_LANGUAGE || "pt-BR"
  );
}

export function formatTextArray(texts: any[]): string {
  try {
    let div = "";
    texts.forEach((item: any) => {
      div += `<p>${item}</p>`;
    });
    return div;
  } catch (_) {
    return "";
  }
}

export function getTranslate(data: {
  text?: string | null;
  long_text?: boolean;
  i18n?: boolean;
  concat?: any;
}): any {
  const text = get(data, "text", null);
  const i18n = get(data, "i18n", true);
  const longText = get(data, "long_text", false);
  const concat = data.concat ?? {};

  if (i18n) {
    const { t, tm } = useI18n();

    if (!longText) {
      return t(text || "empty", { concat: concat }) ?? "";
    } else {
      return formatTextArray(Object(tm(text || "array_empty")));
    }
  }
}
