import CryptoJS from "crypto-js";

export const encrypt = (data: string | null | undefined) => {
  if (!data) return "";

  const key = process.env.VUE_APP_KEY ?? "";
  if (key === "") return;

  const encrypted = CryptoJS.AES.encrypt(data, key).toString();
  return encrypted;
};

// Função para descriptografar dados
export const decrypt = (data: string | null | undefined) => {
  if (!data) return null;

  const key = process.env.VUE_APP_KEY ?? "";
  if (key === "") return;

  const decrypted = CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8);
  return decrypted;
};
