<script setup lang="ts">
import { ref, watchEffect, onMounted, onBeforeUnmount } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { getImage } from "@/common/utils/images";
import { getTranslate } from "@/common/utils/texts";
import { goToLink } from "@/common/utils/link";
import { config } from "@/config";
import { setUser, setProfile } from "@/common/services/users";
import { toast } from "@/common/utils/toast";
import { getRandomNumber } from "@/common/utils/common";
import { onBeforeRouteLeave } from "vue-router";
import store from "@/store";

import { BaseInput, BaseButton } from "@/components";
import { useUserLogin, useUserProfile } from "@/common/hooks/users";

const interval = ref();
const login = ref<string>();
const password = ref<string>();
const showPassword = ref(false);
const errors = ref<{ [key: string]: string }[]>([]);

const rules = {
  login: { required, minLength: minLength(2) },
  password: { required, minLength: minLength(6) },
};

const validate = useVuelidate(rules, { login, password });

const { mutateAsync: userLogin, isLoading: loadingLogin } = useUserLogin();
const { mutateAsync: userProfile } = useUserProfile();

function messages(type: string, lenght: number) {
  const types = {
    required: getTranslate("error.required"),
    min: getTranslate({
      text: "error.min",
      concat: { min: lenght },
    }),
  };

  return types[type as never];
}

function togglePassword() {
  showPassword.value = !showPassword.value;
}

function isValid() {
  errors.value = [];
  if (validate.value.login.$invalid) {
    const length = login.value?.length | 0;
    const type = length > 0 && length < 2 ? "min" : "required";
    errors.value.push({ login: type });
  }
  if (validate.value.password.$invalid) {
    const length = password.value?.length | 0;
    const type = length > 0 && length < 8 ? "min" : "required";
    errors.value.push({ password: type });
  }

  return !validate.value.$invalid;
}

function checkError(key: string) {
  const error = errors.value.filter((item) => Object.keys(item).includes(key));
  return error?.length > 0;
}

function errorMessage(key: string) {
  const [error] = errors.value.filter((item) =>
    Object.keys(item).includes(key)
  );

  if (!error) return;

  return messages(error[key], key === "login" ? 2 : 8);
}

function removeError() {
  errors.value = [];
}

function setUserData(data: any) {
  setUser(data);
  userProfile(data.id, {
    onSuccess: (response) => {
      const { success, data } = response;

      if (success)
        setProfile(data);

      goToLink(`/home`);
    }
  });
}

function sendLogin() {
  if (!isValid()) return;
  userLogin(
    {
      login: login.value as string,
      password: password.value as string,
    },
    {
      onSuccess: async (response) => {
        const { success, data } = response;
        if (success) {
          setUserData(data);
        } else {
          toast({
            message: data?.message,
            type: "error",
          });
        }
      },
    }
  );
}

function changeBackgroundImage() {
  const randomImages = [
    getImage({ image: "images/banner1.jpg" }),
    getImage({ image: "images/banner2.jpg" }),
    getImage({ image: "images/banner3.jpg" }),
  ];

  const backgroundIndex = getRandomNumber(0, randomImages?.length);
  const [page] = document.getElementsByClassName("login-page");
  if (page) {
    const backgroundImage = `url(${randomImages[backgroundIndex]})`;
    page.style.setProperty("background-image", backgroundImage);
  }
}

onBeforeUnmount(() => {
  clearInterval(interval.value);
});

onBeforeRouteLeave(() => {
  clearInterval(interval.value);
});

watchEffect(() => {
  store.commit("SET_LOADING", loadingLogin.value);

  if (store.getters.logged) goToLink("/home");
});

onMounted(() => {
  interval.value = setInterval(changeBackgroundImage, 10000);
});
</script>

<template>
  <section class="login-page">
    <div class="box-login shadow">
      <div class="box-login__left">
        <div>
          <img :src="getImage({ image: config.logotipo.primary_white })" />
        </div>
      </div>

      <div class="box-login__right">
        <div class="title">
          {{ getTranslate({ text: "login.titles.login" }) }}
        </div>
        <form class="form" @submit.prevent="sendLogin()">
          <BaseInput
            name="login"
            :sufix="true"
            :value="login"
            :placeholder="getTranslate({ text: 'login.placeholders.login' })"
            color="#000"
            background="#fff"
            :error="checkError('login')"
            :error-message="errorMessage('login')"
            @update:value="(login = $event), removeError()"
          >
            <template v-slot:sufix>
              <img :src="getImage({ image: 'icons/black/person.png' })" />
            </template>
          </BaseInput>

          <BaseInput
            name="password"
            :sufix="true"
            :sufix-click="true"
            :type="showPassword ? 'text' : 'password'"
            :value="password"
            :placeholder="getTranslate({ text: 'login.placeholders.password' })"
            color="#000"
            background="#fff"
            :error="checkError('password')"
            :error-message="errorMessage('password')"
            @update:value="(password = $event), removeError()"
            @click:sufix="togglePassword()"
          >
            <template v-slot:sufix>
              <img
                :src="
                  getImage({
                    image: showPassword
                      ? 'icons/black/lock-open.png'
                      : 'icons/black/lock-closed.png',
                  })
                "
              />
            </template>
          </BaseInput>

          <div class="form__actions">
            <BaseButton
              width="70%"
              background="#F29826"
              background-active="#fda22a"
              color="black"
              color-active="black"
              dotted-color="black"
              dotted-color-active="black"
              :uppercase="true"
              :bold="true"
              :dotted="false"
            >
              {{ getTranslate({ text: "common.labels.enter" }) }}
            </BaseButton>
            <div class="recover no-select" @click="goToLink('/recover')">
              {{ getTranslate({ text: "login.labels.recover" }) }}
            </div>
            <div class="register no-select">
              <div>
                {{ getTranslate({ text: "login.labels.dont_account" }) }}
              </div>
              <div class="bold" @click="goToLink('/register')">
                {{ getTranslate({ text: "login.titles.register" }) }}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
$box-radius: 0.625rem;
$box-height: 30rem;

.login-page::before {
  opacity: 1;
  transition: opacity 1s 0;
}

.login-page::after {
  opacity: 0.5;
  transition: opacity 1s ease;
}

.login-page {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background: var(--white-off);
  background-image: url("@/assets/images/banner1.jpg");
  background-size: cover;
  background-position: center;

  .title {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }

  .box-login {
    display: grid;
    grid-template-columns: 13.625rem auto;
    width: 38rem;
    min-height: $box-height;
    flex-direction: row;
    border-radius: $box-radius;

    &__left {
      display: flex;
      min-height: $box-height;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      background: #0d0f53d3;
      border-top-left-radius: $box-radius;
      border-bottom-left-radius: $box-radius;

      img {
        width: 10rem;
      }
    }

    &__right {
      display: flex;
      min-height: $box-height;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: var(--white-opacity);
      border-top-right-radius: $box-radius;
      border-bottom-right-radius: $box-radius;
      gap: 1.5rem;

      .form {
        display: flex;
        width: calc(100% - 8rem);
        flex-direction: column;
        gap: 1rem;

        &__actions {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 1.5rem;
          margin-block-start: 0.5rem;

          .recover {
            cursor: pointer;
            font-size: 1rem;
            font-weight: bold;
          }

          .register {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            font-size: 1rem;
            margin-block-start: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .login-page {
    align-items: center;
    justify-content: flex-start;

    .box-login {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;

      &__left {
        width: 100%;
        min-width: 100%;
        height: 13.625rem;
        min-height: 13.625rem;
        max-height: 13.625rem;
        flex-direction: row;
        border-radius: 0;
      }

      &__right {
        width: 100%;
        height: 100%;
        border-radius: 0;

        .form {
          width: 80%;
        }
      }
    }
  }
}
</style>
