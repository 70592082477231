export const concurses = {
    labels: {
        my_concurses: "Meus concursos",
    },
    status: {
        pending: "Pendente",
        approval: "Aprovado",
        disapproved: "Reprovado",
        progress: "Em andamento",
        canceled: "Canceledo",
    },
};
  