<script setup lang="ts">
import { ref, computed, watchEffect, onMounted, onBeforeUnmount } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { getImage } from "@/common/utils/images";
import { getTranslate } from "@/common/utils/texts";
import { goToLink } from "@/common/utils/link";
import { config } from "@/config";
import { toast } from "@/common/utils/toast";
import { getRandomNumber } from "@/common/utils/common";
import store from "@/store";
import { BaseInput, BaseButton } from "@/components";
import { onBeforeRouteLeave } from "vue-router";

import { useUserRecover } from "@/common/hooks/users";

const interval = ref();
const email = ref<string>("");
const errors = ref<{ [key: string]: string }[]>([]);

const rules = {
    email: { required, minLength: minLength(2) },
};

const validate = useVuelidate(rules, { email });

const { mutateAsync: userRecover, isLoading: loadingLogin } = useUserRecover();

function messages(type: string, lenght: number) {
    const types = {
        required: getTranslate("error.required"),
        min: getTranslate({
            text: "error.min",
            concat: { min: lenght },
        }),
    };

    return types[type as never];
}

function isValid() {
    errors.value = [];
    if (validate.value.email.$invalid) {
        const length = email.value?.length | 0;
        const type = length > 0 && length < 2 ? "min" : "required";
        errors.value.push({ email: type });
    }

    return !validate.value.$invalid;
}

function checkError(key: string) {
    const error = errors.value.filter((item) => Object.keys(item).includes(key));
    return error?.length > 0;
}

function errorMessage(key: string) {
    const [error] = errors.value.filter((item) =>
        Object.keys(item).includes(key)
    );

    if (!error) return;

    return messages(error[key], key === "login" ? 2 : 8);
}

function removeError() {
    errors.value = [];
}

function sendEmail() {
    if (!isValid())
        return;

    userRecover(email.value, {
        onSuccess: (response) => {
            const { success, data } = response;

            if (success) {
                toast({
                    message: data.message,
                    type: "success",
                });
            } else {
                toast({
                    message: data.message,
                    type: "error",
                });
            }
        },
        onError: () => {
            toast({
                message: getTranslate({ text: "error.bad_request" }),
                type: "error",
            });
        }
    });
}

function changeBackgroundImage() {
    const randomImages = [
        getImage({ image: "images/banner1.jpg" }),
        getImage({ image: "images/banner2.jpg" }),
        getImage({ image: "images/banner3.jpg" }),
    ];

    const backgroundIndex = getRandomNumber(0, randomImages?.length);
    const [page] = document.getElementsByClassName("login-page");
    if (page) {
        const backgroundImage = `url(${randomImages[backgroundIndex]})`;
        page.style.setProperty("background-image", backgroundImage);
    }
}

onBeforeUnmount(() => {
    clearInterval(interval.value);
});

onBeforeRouteLeave(() => {
    clearInterval(interval.value);
});

watchEffect(() => {
    store.commit("SET_LOADING", loadingLogin.value);
});

onMounted(() => {
    interval.value = setInterval(changeBackgroundImage, 10000);
});
</script>

<template>
    <section class="login-page">
        <div class="box-login shadow">
            <div class="box-login__left">
                <div>
                    <img :src="getImage({ image: config.logotipo.primary_white })" />
                </div>
            </div>

            <div class="box-login__right">
                <div class="title">
                    {{ getTranslate({ text: "login.titles.recover" }) }}
                </div>
                <form class="form" @submit.prevent="sendEmail()">
                    <BaseInput
                        name="email"
                        :sufix="true"
                        :value="email"
                        :placeholder="getTranslate({ text: 'login.placeholders.email' })"
                        color="#000"
                        background="#fff"
                        :error="checkError('email')"
                        :error-message="errorMessage('email')"
                        @update:value="(email = $event), removeError()">
                        <template v-slot:sufix>
                            <img :src="getImage({ image: 'icons/black/e-mail.png' })" />
                        </template>
                    </BaseInput>

                    <div class="form__actions">
                        <BaseButton width="70%" background="#F29826" background-active="#fda22a" color="black"
                            color-active="black" dotted-color="black" dotted-color-active="black" :uppercase="true"
                            :bold="true" :dotted="false">
                            {{ getTranslate({ text: "common.labels.send" }) }}
                        </BaseButton>
                        <div class="register no-select">
                            <div>
                                {{ getTranslate({ text: "login.labels.already_account" }) }}
                            </div>
                            <div class="bold" @click="goToLink('/login')">
                                {{ getTranslate({ text: "login.titles.login" }) }}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
$box-radius: 0.625rem;
$box-height: 30rem;

.login-page::before {
    opacity: 1;
    transition: opacity 1s 0;
}

.login-page::after {
    opacity: 0.5;
    transition: opacity 1s ease;
}

.login-page {
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    background: var(--white-off);
    background-image: url("@/assets/images/banner1.jpg");
    background-size: cover;
    background-position: center;

    .title {
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
    }

    .box-login {
        display: grid;
        grid-template-columns: 13.625rem auto;
        width: 38rem;
        min-height: $box-height;
        flex-direction: row;
        border-radius: $box-radius;

        &__left {
            display: flex;
            min-height: $box-height;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            background: #0d0f53d3;
            border-top-left-radius: $box-radius;
            border-bottom-left-radius: $box-radius;

            img {
                width: 10rem;
            }
        }

        &__right {
            display: flex;
            min-height: $box-height;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: var(--white-opacity);
            border-top-right-radius: $box-radius;
            border-bottom-right-radius: $box-radius;
            gap: 1.5rem;

            .form {
                display: flex;
                width: calc(100% - 8rem);
                flex-direction: column;
                gap: 1rem;

                &__actions {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 1.5rem;
                    margin-block-start: 0.5rem;

                    .recover {
                        cursor: pointer;
                        font-size: 1rem;
                        font-weight: bold;
                        text-align: center;
                    }

                    .register {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 10px;
                        cursor: pointer;
                        font-size: 1rem;
                        margin-block-start: 20px;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .login-page {
        align-items: center;
        justify-content: flex-start;

        .box-login {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;

            &__left {
                width: 100%;
                min-width: 100%;
                height: 13.625rem;
                min-height: 13.625rem;
                max-height: 13.625rem;
                flex-direction: row;
                border-radius: 0;
            }

            &__right {
                width: 100%;
                height: 100%;
                border-radius: 0;

                .form {
                    width: 80%;
                }
            }
        }
    }
}
</style>
