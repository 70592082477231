import { get, put } from "../rest";
import { getLanguage } from "@/common/utils/texts";
import { getUser } from "@/common/services/users";

function getHeaders() {
  const { token, id } = getUser() ?? {};

  return {
    "language": getLanguage(),
    "token": token,
    "id": id,
  };
}

export async function candidatesList(member_id: string) {
  return await get(`member/candidates/${member_id}`, getHeaders());
}

export async function candidatesCheckin(candidate_id: string) {
  return await put(`admin/candidates/checkin/${candidate_id}`, {}, getHeaders());
}