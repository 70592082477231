export const gift = {
    titles: {
      rescue: "Resgate de recompensas",
      rescue_code: "Resgate seu código",
      my_gifts: "Meus gifts",
    },
    labels: {
      recover: "Recover Password!",
    },
    placeholders: {
        enter_code: "Enter your code",
        code_exemple: "Ex: ABCDE123",
    },
  };
  