<script setup lang="ts">
import { ref, watchEffect } from "vue";
import { getImage } from "@/common/utils/images";

interface OptionsType {
  icon?: string;
  label: string;
  value: string;
}
interface PropsType {
  icon?: string | null;
  iconAlt?: string;
  size?: string;
  showArrow?: boolean;
  opened?: boolean | null;
  options?: OptionsType[];
  background?: string;
  backgroundActive?: string;
}

const props = withDefaults(defineProps<PropsType>(), {
  icon: null,
  size: "2rem",
  showArrow: true,
  opened: null,
  background: "#fff",
  backgroundActive: "#ededed",
});

const emit = defineEmits(["open", "change"]);

const show = ref(false);

function toggleOpen() {
  show.value = !show.value;
  emit("open", show.value);
}

function change(key: string) {
  emit("change", key);
  toggleOpen();
}

watchEffect(() => {
  if (props.opened !== null) if (!props.opened) show.value = false;
});
</script>

<template>
  <section class="select-multiple">
    <button class="box-icon" :alt="iconAlt" @click="toggleOpen()">
      <img v-if="icon" :src="icon" class="icon" aria-hidden="true" />
      <img
        class="arrow"
        v-if="showArrow && !show"
        :src="getImage({ image: 'icons/black/arrow-min-down.png' })"
        aria-hidden="true"
      />
      <img
        class="arrow"
        v-if="showArrow && show"
        :src="getImage({ image: 'icons/black/arrow-min-up.png' })"
        aria-hidden="true"
      />
    </button>
    <template v-if="(show && opened === null) || (show && opened)">
      <div class="box-content shadow">
        <template v-if="options">
          <ul>
            <template v-for="(option, index) in options" :key="index">
              <li>
                <button @click="change(option.value)">
                  <img v-if="option.icon" :src="option.icon" class="icon" />
                  <span class="label">
                    {{ option.label }}
                  </span>
                </button>
              </li>
            </template>
          </ul>
        </template>
        <template v-else>
          <slot></slot>
        </template>
      </div>
    </template>
  </section>
</template>

<style lang="scss" scoped>
.select-multiple {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  background: v-bind("background");

  .box-icon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: calc(0.875rem + v-bind("size"));
    height: v-bind("size");
    background: transparent;
    border: none;

    .icon {
      width: v-bind("size");
      height: v-bind("size");
    }

    .arrow {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  .box-content {
    position: absolute;
    margin-block-start: calc(1rem + v-bind("size"));
    display: flex;
    border: 1px solid var(--black-full);
    padding: 0.5rem;
    border-radius: 0.4rem;
    background: #fff;

    ul {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      button {
        cursor: pointer;
        display: flex;
        width: 100%;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
        padding-inline: 1rem;
        background: transparent;
        border: none;

        .icon {
          width: v-bind("size");
        }

        .label {
          font-size: 1rem;
        }
      }

      button:hover {
        background: v-bind("backgroundActive");
      }
    }
  }
}
</style>
