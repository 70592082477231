<script setup lang="ts">
import store from "@/store";
import { useI18n } from "vue-i18n";
import { ref, computed, onMounted, onBeforeMount } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import { getImage } from "@/common/utils/images";
import { getTranslate, getLanguage } from "@/common/utils/texts";
import { checkRouter } from "@/common/utils/link";
import { logOutUser } from "@/common/services/users";

import { BaseSelect, BaseButton } from "../";

const menuOpen = ref(false);

const { locale } = useI18n();

const leftMenus = computed(() => [
  {
    route: "/home",
    label: getTranslate({ text: "common.labels.home" })?.toUpperCase(),
    icon: getImage({ image: "icons/white/home-stoned.png" }),
    iconActive: getImage({ image: "icons/black/home-stoned.png" }),
  },
  {
    route: "/profile",
    label: getTranslate({ text: "common.labels.my_account" })?.toUpperCase(),
    icon: getImage({ image: "icons/white/shield.png" }),
    iconActive: getImage({ image: "icons/black/shield.png" }),
  },
  {
    route: "/credentials",
    label: getTranslate({ text: "common.labels.credentials" })?.toUpperCase(),
    icon: getImage({ image: "icons/white/ticket.png" }),
    iconActive: getImage({ image: "icons/black/ticket.png" }),
  },
  {
    route: "/concurses",
    label: getTranslate({ text: "common.labels.concurses" })?.toUpperCase(),
    icon: getImage({ image: "icons/white/trophy.png" }),
    iconActive: getImage({ image: "icons/black/trophy.png" }),
  },
]);

const languagesOptions = computed(() => [
  {
    icon: getImage({ image: "flags/pt-br.jpg" }),
    label: getTranslate({ text: "common.languages.pt_br" }).toUpperCase(),
    value: "pt-BR",
  },
  {
    icon: getImage({ image: "flags/en-us.jpg" }),
    label: getTranslate({ text: "common.languages.en_us" }).toUpperCase(),
    value: "en-US",
  },
]);

const languageSelected = computed(() => store.getters.language);

const placeholderLanguageSelected = computed(() => {
  const type = String(store.getters.language || "pt-BR")
    .replace("-", "_")
    .toLowerCase();

  return getTranslate({
    text: `common.languages.${type}`
  }).toUpperCase();
});

function changeLanguage(value: string) {
  locale.value = value;
  localStorage.setItem("locale", value);
  store.commit("SET_LANGUAGE", value);
}

function toggleMenu() {
  menuOpen.value = !menuOpen.value;

  const body = document.body;

  if (menuOpen.value) {
    body.style.height = "100vh";
    body.style.overflow = "hidden";
  } else {
    body.style.height = "auto";
    body.style.overflow = "auto";
  }
}

onBeforeRouteLeave(() => {
  const body = document.body;
  body.style.height = "auto";
  body.style.overflow = "auto";
});

onBeforeMount(() => {
  const lang = getLanguage();
  store.commit("SET_LANGUAGE", lang);
});
</script>

<template>
  <header class="app-bar">
    <section class="brand">
      <router-link to="/">
        <img
          class="logotipo"
          :src="getImage({ image: 'images/logo-primary.png' })"
        />
      </router-link>
    </section>
    <section class="content">
      <div class="right-menu">
        <div>
          <BaseSelect
            :placeholder="placeholderLanguageSelected"
            :selected="languageSelected"
            :options="languagesOptions"
            color="#fff"
            color-active="#F29826"
            background="transparent"
            background-active="transparent"
            option-background-active="#F29826"
            @change="changeLanguage($event)"
          />
        </div>
        <button class="bars" @click="toggleMenu()">
          <li class="pi pi-bars" />
        </button>
      </div>
    </section>
    <section v-if="menuOpen" class="box-menu">
      <div class="menu">
        <ul>
          <template v-for="(item, index) in leftMenus" :key="index">
            <li :class="{ active: checkRouter(item.route) }">
              <router-link :to="item.route" @click="toggleMenu()">
                <img v-if="checkRouter(item.route)" class="icon" :src="item.iconActive" />
                <img v-else class="icon" :src="item.icon" />
                {{ item.label }}
              </router-link>
            </li>
          </template>
          <li @click="logOutUser()">
            <span>
              <img class="icon" :src="require('@/assets/icons/white/exit.png')" />
              {{ getTranslate({ text: "common.labels.exit" }) }}
            </span>
          </li>
        </ul>
      </div>
    </section>
  </header>
</template>

<style lang="scss" scoped>
$height: 6rem;
$logotipo: 4rem;

.app-bar {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
  height: $height;

  .brand {
    display: flex;
    flex-direction: column;
    background: #0D0F53;
    justify-content: center;

    .logotipo {
      cursor: pointer;
      width: auto;
      height: $logotipo;
      padding-inline-start: var(--padding-inline);
      z-index: 99;
    }
  }

  .content {
    height: $height;

    .right-menu {
      display: flex;
      height: $height;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 1.5rem;
      padding-inline-end: var(--padding-inline);
      background: #0D0F53;

      .bars {
        background: transparent;
        color: var(--white-full);
        font-size: 1.5rem;
        outline: none;
        border: none;
        cursor: pointer;
      }

      .bars:hover {
        color: var(--primary);
      }
    }

    .line {
      cursor: pointer;
      width: 100%;
      height: 7px;
      background: #000;

      &-right {
        width: 100%;
        height: 7px;
        background: var(--primary);
        clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }

  .box-menu {
    position: absolute;
    z-index: 98;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #000000c5;
    width: 100%;
    margin-block-start: $height;
    height: calc(100vh - $height);

    .menu {
      width: 75%;
      margin-inline: auto;
      margin-block-start: 5rem;
    }

    ul {
      display: flex;
      height: min-content;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.1rem;
        width: 100%;
        padding-block: 0.5rem;
        padding-inline: 0.75rem;
        border-radius: 0.5rem;
        color: var(--white-full);
        cursor: pointer;

        span,
        a {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;
          color: var(--white-full);
          text-decoration: none;
          text-transform: uppercase;
        }

        .icon {
          width: 2.5rem;
        }
      }

      li.active {
        width: 100%;
        padding-block: 0.5rem;
        padding-inline: 0.75rem;
        border-radius: 0.5rem;
        background: var(--primary);

        a {
          color: var(--black-full);
        }
      }
    }
  }
}
</style>
