import axios from "axios";

export const get = async (route: string, headers?: any) => {
  const server = process.env.VUE_APP_API ?? "";
  return axios
    .get(`${server}/${route}`, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const post = async (route: string, payload: any, headers?: any) => {
  const server = process.env.VUE_APP_API ?? "";
  return await axios
    .post(`${server}/${route}`, payload, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
};

export const put = async (route: string, payload: any, headers?: any) => {
  const server = process.env.VUE_APP_API ?? "";
  return axios
    .put(`${server}/${route}`, payload, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const del = async (route: string, headers?: any) => {
  const server = process.env.VUE_APP_API ?? "";
  return axios
    .delete(`${server}/${route}`, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
