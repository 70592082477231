import { get } from "lodash-es";
import domtoimage from 'dom-to-image';

export const getImage = (data: {
  image?: string | null;
  is_local?: boolean;
}) => {
  const image = get(data, "image", null);
  const isLocal = get(data, "is_local", true);

  if (!image) {
    return require(`@/assets/icons/black/no-image.png`);
  }
  try {
    const host = process.env.VUE_APP_HOST;
    const newImage = require(`@/assets/${image}`);

    if (!isLocal)
      return image;

    if (newImage.includes("base64"))
      return newImage;

    return `${host}${newImage}`;
  } catch (error) {
    console.log("error", error);
    return null;
  }
};

export async function divToImage(name = "image.jpg", div: any) {
  const dataUrl = await domtoimage.toJpeg(div);

  // Cria um link temporário para download da imagem
  const link = document.createElement('a');
  link.href = dataUrl;
  link.download = name;
  document.body.appendChild(link);

  // Dispara o clique no link para iniciar o download
  link.click();

  // Remove o link temporário
  document.body.removeChild(link);
}

export function redimensionarImagem(file: File, maxWidth: number, callback: any) {
  const img = new Image();

  img.onload = function () {
    const width = img.width;
    let height = img.height;

    // Calcula a proporção de redimensionamento com base na largura máxima
    const ratio = maxWidth / width;

    // Calcula a altura com base na proporção de redimensionamento
    height = height * ratio;

    // Cria um canvas para redimensionar a imagem
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = maxWidth;
    canvas.height = height;

    // Desenha a imagem no canvas redimensionado
    ctx?.drawImage(img, 0, 0, maxWidth, height);

    // Converte o canvas de volta para um Blob
    canvas.toBlob(function (blob) {
      // Chama a função de callback com o Blob resultante
      callback(blob);
    }, file.type);
  };

  // Carrega a imagem
  img.src = URL.createObjectURL(file);
}

export async function convertFileToBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
