<script setup lang="ts">
import { computed, ref } from "vue";
import { getTranslate } from "@/common/utils/texts";

interface OptionsType {
  label: string;
  value: string | number;
  icon?: string | null;
}
interface PropsType {
  label?: string;
  placeholder?: string;
  selected?: string | number | null;
  options?: OptionsType[];
  background?: string;
  backgroundActive?: string;
  color?: string;
  colorActive?: string;
  radius?: string;
  dotted?: boolean;
  dottedColor?: string;
  fontSize?: string;
  optionBackground?: string;
  optionBackgroundActive?: string;
  optionColor?: string;
  optionColorActive?: string;
}

const emit = defineEmits(["change"]);

const props = withDefaults(defineProps<PropsType>(), {
  background: "#fff",
  backgroundActive: "#e8e8e8",
  color: "#000",
  colorActive: "#000",
  radius: "0.5rem",
  dotted: false,
  dottedColor: "#000",
  fontSize: "1rem",
  optionBackground: "#fff",
  optionBackgroundActive: "#e8e8e8",
  optionColor: "#000",
  optionColorActive: "#000",
});

const show = ref(false);

const placeholderValue = computed(() => {
  if (props.selected) {
    const data = (props.options || []).filter(
      (option) => option.value === props.selected
    );

    return data[0]?.label;
  }

  return props.placeholder ?? getTranslate({ text: "common.labels.select" });
});

function toggle() {
  show.value = !show.value;
}

function onSelect(value: string) {
  emit("change", value);
  toggle();
}
</script>

<template>
  <div class="base-select">
    <label v-if="label">
      {{ label }}
    </label>
    <button class="select" :class="{ dotted: dotted }" @click="toggle()">
      <template v-if="dotted">
        <div class="dotted">
          <span>
            {{ placeholderValue }}
            <span class="pi pi-chevron-up" />
            <span v-if="!show" class="pi pi-chevron-down" />
            <span v-else class="pi pi-chevron-up" />
          </span>
        </div>
      </template>
      <template v-else>
        <span>
          {{ placeholderValue }}
          <span v-if="!show" class="pi pi-chevron-down" />
          <span v-else class="pi pi-chevron-up" />
        </span>
      </template>
    </button>
    <template v-if="show">
      <div class="options shadow">
        <template v-for="(item, index) in options" :key="index">
          <button class="option" @click="onSelect(item.value)">
            <img v-if="item.icon" :src="item.icon" aria-hidden="true" />
            <span>
              {{ item.label }}
            </span>
          </button>
        </template>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.base-select {
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    display: flex;
    font-size: 0.85rem;
    margin-block: 0.4rem;
    margin-inline: 0.8rem;
  }
  .select {
    cursor: pointer;
    width: 100%;
    height: 2.75rem;
    color: v-bind("color");
    background: v-bind("background");
    padding-block: 0.5rem;
    padding-inline: 0.75rem;
    border: none;
    border-radius: v-bind("radius");
    font-size: v-bind("fontSize");

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;

      img {
        width: 1rem;
      }
    }
  }

  .select:hover {
    background: v-bind("backgroundActive");
    color: v-bind("colorActive");
  }

  .select.dotted {
    padding-block: 0.4rem;
    padding-inline: 0.4rem;

    .dotted {
      border: 2px dotted v-bind("dottedColor");
      padding-block: 0.5rem;
      padding-inline: 0.75rem;
      border-radius: calc(v-bind("radius") - 0.1rem);
    }
  }

  .options {
    position: absolute;
    display: flex;
    width: fit-content;
    flex-direction: column;
    margin-block-start: 4.75rem;
    background: v-bind("optionBackground");
    z-index: 999;

    .option {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: none;
      background: transparent;
      padding-block: 0.75rem;
      padding-inline: 0.9rem;
      color: v-bind("optionColor");
      gap: 0.5rem;

      img {
        width: 1.75rem;
      }
    }

    .option:hover {
      background: v-bind("optionBackgroundActive");
      color: v-bind("optionColorActive");
    }
  }
}
</style>
