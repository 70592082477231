export const EvenOrOdd = (value: number) => {
  return value % 2 === 0 ? "even" : "odd";
};

export const setTitle = (value: string) => {
  try {
    document.title = value;
  } catch (_) {
    return;
  }
};

export const setFavIcon = (value: string) => {
  try {
    if (value) {
      const link: any = document.querySelector("link[rel~='icon']");
      if (!link) return;

      link.href = value;
    }
  } catch (_) {
    return;
  }
};

export function getRandomNumber(min: number, max: number) {
  const random = Math.random();

  const range = random * (max - min) + min;

  return Math.floor(range);
}
