import { get, put } from "../rest";
import { getLanguage } from "@/common/utils/texts";
import { getUser } from "@/common/services/users";


export interface ConcurseType {
    concurse_id: string;
    type: string;
    status: string;
    name: string;
    event_date: string;
    used: number;
    available: number;
    description: string;
    created: string;
}

function getHeaders() {
  const { token, id } = getUser() ?? {};

  return {
    "content-type": "application/json",
    "language": getLanguage(),
    "token": token,
    "id": id,
  };
}

export async function concurseList() {
  return await get("admin/concurses", getHeaders());
}

export async function concurseGet(concurse_id: string) {
  return await get(`admin/concurses/${concurse_id}`, getHeaders());
}

export async function concurseJurors(member_id: string) {
  return await get(`member/jurors/${member_id}`, getHeaders());
}

export async function concurseStatus(concurse_id: string, status: string ) {
  return await put(`admin/concurses/status/${concurse_id}`, { status }, getHeaders());
}

export async function concurseResult(concurse_id: string ) {
  return await get(`admin/concurses/result/${concurse_id}`, getHeaders());
}
