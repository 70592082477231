import { MD5 } from "crypto-js";
import { useQuery, useMutation, useQueryClient } from "@tanstack/vue-query";
import {
  userLogin,
  validToken,
  userProfile,
  userProfileUpdate,
  userRecover,
  userRecoverHash,
  userUpdatePass,
  userRegister,
} from "../api/rest/users";

import { UserProfileType } from "@/common/schemas";
import { getDateISO } from "@/common/utils/dates";

export function useUserLogin() {
  return useMutation({
    mutationKey: ["login"],
    mutationFn: (data: { login: string; password: string }) => {
      return userLogin({
        login: data.login,
        password: MD5(data.password).toString(),
      });
    },
  });
}

export function useUserProfile() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["user", "profile"],
    mutationFn: async (id: string) => userProfile(id),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["user", "profile"] });
    },
  });
}

export function useUserRecover() {
  return useMutation({
    mutationKey: ["user", "recover"],
    mutationFn: async (email: string) => userRecover(email),
  });
}

export function useUserRecoverHash() {
  return useMutation({
    mutationKey: ["user", "recover", "hash"],
    mutationFn: async (hash: string) => await userRecoverHash(hash),
  });
}

export function useUserUpdatePass() {
  return useMutation({
    mutationKey: ["user", "update", "pass"],
    mutationFn: async (data: { hash: string; password: string }) => {
      const payload = {
        ...data,
        password: MD5(data.password).toString(),
      };
      return await userUpdatePass(payload);
    },
  });
}

export function useUserRegister() {
  return useMutation({
    mutationKey: ["user", "update", "pass"],
    mutationFn: async (data: {
      name: string;
      birthdate: string;
      email: string;
      login: string;
      password: string 
    }) => {
      const payload = {
        ...data,
        password: MD5(data.password).toString(),
        birthdate: getDateISO(data.birthdate),
      };
      return await userRegister(payload);
    },
  });
}

export function useUserProfileUpdate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["user", "profile"],
    mutationFn: async (data: { id: string; payload: UserProfileType }) => 
      await userProfileUpdate(data.id, {
        ...data.payload,
        birthdate: getDateISO(data.payload.birthdate || ""),
      }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["user", "profile"] });
    },
  });
}

export function useCheckToken() {
  return useQuery({
    queryKey: ["token"],
    queryFn: async () => await validToken(),
    select: (result) => result?.data?.valid || false,
  });
}
