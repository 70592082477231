export const user = {
    titles: {
        access_data: "Access Data",
        personal_data: "Personal Data",
        avatar: "Avatar",
    },
    labels: {
        avatar: "Avatar",
        birthdate: "Birthdate",
        first_name: "First Name",
        last_name: "Last Name",
        gender: "Gender",
        social_name: "Social Name",
        artistic_name: "Artistic name",
        rg: "ID",
        phone: "Phone",
        whatsapp: "WhatsApp",
        instagram: "Instagram (cannot be private)",
        description: "Description",
        change_picture: "Send picture",
    },
    placeholders: {
        birthdate: "dd/mm/yyyy",
        first_name: "Enter your first name",
        last_name: "Enter your last name",
        gender: "Choose gender",
        social_name: "Enter your social name",
        artistic_name: "Enter your artistic name",
        rg: "ID",
        phone: "Enter your phone number",
        whatsapp: "Enter your WhatsApp",
        instagram: "Enter your Instagram",
        description: "Describe",
    },
    gender: {
        woman: "Feminine",
        man: "Masculine",
        empty: "Prefer not to say",
    }
};
