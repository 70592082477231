<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { getTranslate } from "@/common/utils/text";
import { getImage } from "@/common/utils/images";

interface OptionsType {
  label: string;
  value: string | number;
}
interface PropsType {
  label?: string;
  width?: string;
  height?: string;
  type?: string;
  placeholder?: string;
  selected?: string | number | null;
  options?: OptionsType[];
  background?: string;
  backgroundActive?: string;
  color?: string;
  colorActive?: string;
  radius?: string;
  bold?: boolean;
  uppercase?: boolean;
  dotted?: boolean;
  dottedSize?: string;
  dottedColor?: string;
  dottedColorActive?: string;
  fontSize?: string;
  border?: boolean;
  borderStyle?: string;
}

const emit = defineEmits(["clicked"]);

const props = withDefaults(defineProps<PropsType>(), {
  type: "button",
  width: "max-content",
  height: "max-content",
  background: "#fff",
  backgroundActive: "#e8e8e8",
  color: "#000",
  colorActive: "#000",
  radius: "0.5rem",
  bold: false,
  uppercase: false,
  dotted: false,
  dottedSize: "2px",
  dottedColor: "#000",
  dottedColorActive: "#000",
  fontSize: "1rem",
  borderStyle: "1px solid #000",
});

const fontUppercase = computed(() => (props.uppercase ? "uppercase" : "normal"));
const fontWeight = computed(() => (props.bold ? "bold" : "normal"));

const styleBorder = computed(() =>
  props.border ? props.borderStyle : "none"
);

function clicked() {
  emit("clicked");
}
</script>

<template>
  <button class="base-button" :class="{ dotted: dotted }" @click="clicked()">
    <template v-if="dotted">
      <div class="dotted">
        <slot></slot>
      </div>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </button>
</template>

<style lang="scss" scoped>
$width: v-bind("width");
$height: v-bind("height");
$color: v-bind("color");
$color-active: v-bind("colorActive");
$background: v-bind("background");
$background-active: v-bind("backgroundActive");
$border: v-bind("styleBorder");
$radius: v-bind("radius");
$font-size: v-bind("fontSize");
$font-weight: v-bind("fontWeight");
$font-uppercase: v-bind("fontUppercase");
$dotted-size: v-bind("dottedSize");
$dotted-color: v-bind("dottedColor");
$dotted-active: v-bind("dottedColorActive");

.base-button {
  cursor: pointer;
  width: $width;
  height: $height;
  background: $background;
  padding-block: 0.75rem;
  padding-inline: 0.75rem;
  border: $border;
  color: $color;
  border-radius: $radius;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: 1.1;
  text-transform: $font-uppercase;

  .dotted {
    border: $dotted-size dotted $dotted-color;
    padding-block: 0.5rem;
    padding-inline: 1rem;
    border-radius: calc($radius - 0.1rem);
  }
}

.base-button:hover {
  background: $background-active;
  color: $color-active;

  .dotted {
    border: $dotted-size dotted $dotted-active;
  }
}

.base-button.dotted {
  padding-block: 0.4rem;
  padding-inline: 0.5rem;
}
</style>
