<script setup lang="ts">
import { withDefaults, ref } from "vue";
import { RouterLink } from "vue-router";
import { checkRouter } from "@/common/utils/link";

interface MenuItemsType {
  label: string;
  icon?: string;
  iconActive?: string;
  url?: string;
}

interface PropsType {
  menuItems?: MenuItemsType[] | null;
  logo?: string | null;
  logoAlt?: string;
  footerLogo?: string | null;
  footerLogoAlt?: string;
  homeUrl?: string;
  width?: string;
  backgroundColor?: string;
  fontColor?: string;
  fontColorActive?: string;
  menuColor?: string;
  menuColorActive?: string;
}

withDefaults(defineProps<PropsType>(), {
  menuItems: null,
  logo: null,
  logoAlt: "Logotipo",
  footerLogo: null,
  footerLogoAlt: "Logotipo",
  homeUrl: "#",
  width: "15rem",
  backgroundColor: "#c9c9c9",
  fontColor: "#000",
  fontColorActive: "#000",
  menuColor: "transparent",
  menuColorActive: "#fff",
});

const itemHover = ref();
</script>

<template>
  <aside class="aside">
    <section class="logo">
      <a :href="homeUrl">
        <img v-if="logo" :src="logo" :alt="logoAlt" />
      </a>
    </section>
    <section class="menu">
      <ul>
        <template v-for="(menu, index) in menuItems" :key="index">
          <li
            :aria-current="checkRouter(menu.url)"
            @mouseover="itemHover = index"
            @mouseout="itemHover = null"
          >
            <router-link :to="menu.url || '#'">
              <img
                v-if="
                  (checkRouter(menu.url) && menu.iconActive) ||
                  (itemHover == index && menu.iconActive)
                "
                class="icon"
                :src="menu.iconActive"
                aria-hidden="true"
              />
              <img
                v-else-if="
                  (!checkRouter(menu.url) && menu.iconActive) ||
                  (itemHover != index && !menu.iconActive) ||
                  !menu.iconActive
                "
                class="icon"
                :src="menu.icon"
                aria-hidden="true"
              />
              <span class="label">
                {{ menu.label }}
              </span>
            </router-link>
          </li>
        </template>
      </ul>
    </section>
    <section v-if="footerLogo" class="logo-footer">
      <img :src="footerLogo" :alt="footerLogoAlt" />
    </section>
  </aside>
</template>

<style lang="scss" scoped>
.aside {
  position: fixed;
  display: grid;
  grid-template-rows: 1fr 4fr 1fr;
  width: calc(v-bind("width") - 3rem);
  height: calc(100vh - 2rem);
  background: v-bind("backgroundColor");
  padding-block: 1rem;
  padding-inline: 1.5rem;
  gap: 1.5rem;

  .logo {
    display: flex;
    justify-content: center;

    img {
      max-width: 13rem;
    }
  }

  .menu {
    display: flex;

    ul {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-block: 0;
      margin-inline: 0;
      padding-inline: 0;
      list-style-type: none;
      gap: 0.5rem;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
        gap: 0.5rem;

        .label {
          font-size: 1rem;
          font-weight: 500;
          color: v-bind("fontColor");
        }

        .icon {
          width: 2.1rem;
        }
      }

      li {
        height: 38px;
        padding-block: 0.1rem;
        padding-inline: 0.25rem;
        background: v-bind("menuColor");
        border-radius: 0.5rem;

        .label {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 34px;
        }
      }

      li:hover {
        background: v-bind("menuColorActive");

        a .label {
          color: v-bind("fontColorActive");
        }
      }

      li[aria-current="true"] {
        background: v-bind("menuColorActive");

        a .label {
          color: v-bind("fontColorActive");
        }
      }
    }
  }

  .logo-footer {
    display: flex;
    justify-content: center;

    img {
      max-width: 6.25rem;
    }
  }
}
</style>
