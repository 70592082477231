<script setup lang="ts">
import { onMounted, ref } from "vue";
import { toast } from "@/common/utils/toast";
import store from "@/store";
import { BaseInput, BaseSelect, BaseTextarea, BaseConfirm } from '@/components';

import { redimensionarImagem, convertFileToBase64 } from "@/common/utils/images";

import { addCandidateImage, fetchCandidateImage, delCandidateImage } from "@/common/api/rest/files";

export interface PayloadType {
    name: string;
    personage: string;
    universe: string;
    url_mp3: string;
    url_video: string;
    description: string;
}

const props = defineProps<{
    payload: PayloadType
    images: string[];
    type: "parade" | "concurse";
}>();

const emit = defineEmits(["update:values", "save:values", "back", "fetch:values"]);

const file = ref<string | null>(null);
const avatar_id = ref("");

const imageShow = ref(-1);
const imagesUrls = ref<string[]>([]);

const modalDel = ref(false);
const modalDelMesage = ref(
    "Você tem certeza que deseja remover a imagem?<br>Isso não poderá ser desfeito!"
);

function fetchAvatar() {
    props.images.forEach(async (id, index) => {
        await fetchCandidateImage(id, "large")
            .then((response) => {
                const { success, data } = response;
                if (success) {
                    imagesUrls.value[index] = data.file;
                }
            });
    });
}

function sendImage() {
    addCandidateImage(avatar_id.value, file.value as never)
        .then((response) => {
            const { success, data } = response;
            if (success) {
                file.value = null;
                avatar_id.value = "";
                toast({ message: data.message, type: "success" });
                fetchAvatar();
            }
        })
        .catch(() => {
            avatar_id.value = "";
            toast({ message: "Erro interno do servidor", type: "error" });
        })
        .finally(() => {
            store.commit("SET_LOADING", false);
        });
}

function delImageConfirm() {
    modalDel.value = false;
    store.commit("SET_LOADING", true);
    delCandidateImage(avatar_id.value)
        .then((response) => {
            const { success, data } = response;
            if (success) {
                avatar_id.value = "";
                toast({ message: data.message, type: "success" });
                fetchAvatar();
            }
        })
        .catch(() => {
            avatar_id.value = "";
            toast({ message: "Erro interno do servidor", type: "error" });
        })
        .finally(() => {
            store.commit("SET_LOADING", false);
        });
}

function delImage(id: string) {
    avatar_id.value = id;
    modalDel.value = true;
}

function delImageCancel() {
    avatar_id.value = "";
    modalDel.value = false;
}

function showImage(index: number) {
    if (imagesUrls.value[index])
        imageShow.value = index;
}

async function selectFile(event: any) {
    store.commit("SET_LOADING", true);
    const files = event.target.files[0];
    if (!files) return;
    
    const reader = new FileReader();
    reader.readAsDataURL(files);

    redimensionarImagem(files, 1024, async(resized: File) => {
        const base64String = await convertFileToBase64(resized);
    
        file.value = base64String;

        sendImage();
    })
}

function selectImage(id: string) {
    avatar_id.value = id;
    const el = document.getElementById("file");

    if (el) {
        el.click();
    }
}

function update(key: string, value: string) {
    const payload = {
        ...props.payload,
        [key]: value,
    };

    emit("update:values", payload);
}

onMounted(() => {
    fetchAvatar();
})
</script>

<template>
    <div class="form">
        <section>
            <base-input
                name="name"
                label="Candidato *"
                :value="payload.name"
                placeholder="Informe o seu nome"
                @update:value="update('name', $event)"
            />
            <base-input
                name="personage"
                label="Personagem *"
                :value="payload.personage"
                placeholder="Qual o nome do seu personagem?"
                @update:value="update('personage', $event)"
            />
            <base-input
                name="universe"
                label="Universo do personagem *"
                :value="payload.universe"
                placeholder="Universo ( anime , filme e etc )"
                @update:value="update('universe', $event)"
            />
            <base-input
                v-if="type !== 'parade'"
                name="url_mp3"
                label="Vai usar áudio?"
                :value="payload.url_mp3"
                placeholder="Informe a URL do aúdio"
                @update:value="update('url_mp3', $event)"
            />
            <base-input
                v-if="type !== 'parade'"
                name="url_video"
                label="Vai usar vídeo?"
                :value="payload.url_video"
                placeholder="Informe a URL do vídeo"
                @update:value="update('url_video', $event)"
            />
            <base-textarea
                label="Resumo"
                :value="payload.description"
                placeholder="Fale sobre o seu personagem"
                color="#000"
                @update:value="update('description', $event)"
            />
        </section>
        <section class="files">
            <h4>IMAGENS</h4>
            <div class="images">
                <template v-for="(item, index) in images" :key="index">
                    <div class="box">
                        <div class="image" @click="showImage(index)">
                            <img v-if="imagesUrls[index]" :src="imagesUrls[index]" />
                            <span v-else>
                                <img class="no-image" src="@/assets/icons/black/no-image.png" />
                            </span>
                        </div>
                        <div class="actions">
                            <img
                                src="@/assets/icons/black/upload.png"
                                title="Enviar"
                                @click="selectImage(item)"
                            />
                            <img
                                v-if="imagesUrls[index]"
                                src="@/assets/icons/black/trash.png"
                                title="Remover"
                                @click="delImage(item)"
                            />
                        </div>
                    </div>
                </template>
                <input id="file" type="file" @change="selectFile" accept="image/*">
            </div>
        </section>
        <section class="actions">
            <button class="back" @click="emit('back')">
                VOLTAR
            </button>
            <button class="save" @click="emit('save:values')">
                SALVAR
            </button>
        </section>
        <section v-if="imageShow >= 0" class="show-image">
            <div class="box">
                <div class="close" @click="imageShow = -1">
                    <img src="@/assets/icons/black/close.png">
                </div>
                <img class="image" :src="imagesUrls[imageShow]" />
            </div>
        </section>
        <base-confirm
            :show="modalDel"
            title="DELETAR IMAGEM"
            :message="modalDelMesage"
            @confirm="delImageConfirm()"
            @cancel="delImageCancel()"
        />
    </div>
</template>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    gap: 8px;

    section {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .files {
        margin-block-start: 15px;
    }

    .images {
        display: flex;
        flex-wrap: wrap;
        background: #f1f1f1;
        border: 1px solid gray;
        padding: 20px;
        border-radius: 8px;
        justify-content: space-between;
        gap: 15px;

        input[type=file] {
            display: none;
        }

        .box {
            display: flex;
            flex-direction: column;
            width: 150px;
            height: 190px;
            background: #e8e8e8;
        }

        .actions {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 15px;
            height: 30px;
            margin-block-start: 5px;

            img {
                cursor: pointer;
                width: 30px;
                opacity: 0.5;

                &:hover {
                    opacity: 1;
                }
            }
        }

        .image {
            cursor: pointer;
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 150px;
            height: 150px;
            max-height: 150px;
            min-height: 150px;
            background: white;
            overflow: hidden;

            .no-image {
                width: 70px;
                height: 70px;
            }
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-block-start: 15px;

        button {
            cursor: pointer;
            width: fit-content;
            height: 45px;
            padding-block: 5px;
            padding-inline: 20px;
            border: 1px solid transparent;
            border-radius: 8px;
            font-weight: bold;
        }

        .back {
            background: #e8e8e8;
            
            &:hover {
                background: #bebebe;
            }
        }

        .save {
            background: var(--primary);
            padding-inline: 35px;

            &:hover {
                background: var(--primary-hover);
            }
        }
    }

    .show-image {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background: #00000096;

        .box {
            width: 600px;
            padding: 10px;
            background: white;

            .image {
                max-width: 100%;
                max-height: 500px;
            }

            .close {
                display: flex;
                justify-content: flex-end;

                img {
                    user-select: none;
                    width: 30px;
                    margin-block-end: 8px;
                    cursor: pointer;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .form {
        .images {
            .box {
                width: 300px;
                height: 340px;
            }

            .image {
                width: 300px;
                height: 300px;
                min-height: 300px;
                max-height: 300px;
            }
        }
    }
}
</style>