export const gift = {
    titles: {
      rescue: "Rewards redemption",
      rescue_code: "Redeem your code",
      my_gifts: "My gifts",
    },
    labels: {
      recover: "Recover Password!",
    },
    placeholders: {
      enter_code: "Insira seu código",
      code_exemple: "Ex: ABCDE123",
    },
  };
  