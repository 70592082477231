import moment from "moment";
import { createI18n } from "vue-i18n";
import { messages } from "./messages";

const language = process.env.VUE_APP_LANGUAGE ?? "pt-BR";

const storageLocale: string = localStorage.getItem("locale") || language;

const defaultLocale = storageLocale || navigator.language;

moment.locale(defaultLocale);

const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  storageLocale,
  messages,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  globalInjection: true,
  numberFormats: {
    "en-US": {
      currency: {
        style: "currency",
        currency: "USD",
      },
    },
    "pt-BR": {
      currency: {
        style: "currency",
        currency: "BRL",
      },
    },
  },
});

export default i18n;
