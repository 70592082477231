import { Module } from "vuex";
import type { UserType, UserProfileType } from "@/common/schemas";

const userModule: Module<any, any> = {
  state() {
    const user: UserType | null = null;
    const profile: UserProfileType | null = null;

    return {
      logged: false,
      user,
      profile,
      avatar: null,
    };
  },
  getters: {
    logged: (state) => state.logged,
    user: (state) => state.user,
    profile: (state) => state.profile,
    avatar: (state) => state.avatar,
  },
  mutations: {
    SET_USER: (state, data) => {
      state.user = data;
    },
    SET_PROFILE: (state, data) => {
      state.profile = data;
    },
    SET_AVATAR: (state, data) => {
      state.avatar = data;
    },
    SET_LOGGED: (state, data) => {
      state.logged = data;
    },
  },
};

export default userModule;
