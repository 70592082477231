import router from "@/router";

export const goToLink = (url: string | undefined, external = false) => {
  if (!url) return;

  if (!external) router.push(url);
  else window.open(url, "_blank");
};

export const checkRouter = (url: string | undefined) => {
  const currentRoute = router.currentRoute.value.path || "";
  return currentRoute.includes(url || "");
};
