export const user = {
    titles: {
      access_data: "Dados de acesso",
      personal_data: "Dados Pessoais",
      avatar: "Avatar",
    },
    labels: {
        avatar: "Avatar",
        birthdate: "Aniversário",
        first_name: "Nome",
        last_name: "Sobrenome",
        gender: "Gênero",
        social_name: "Nome social",
        artistic_name: "Nome artístico",
        rg: "RG",
        phone: "Telefone",
        whatsapp: "Whatsapp",
        instagram: "Instagram (não pode ser privado)",
        description: "Descrição",
        change_picture: "Enviar Foto",
    },
    placeholders: {
        birthdate: "dd/mm/aaaa",
        first_name: "Insira seu nome",
        last_name: "Insira seu sobrenome",
        gender: "Escolha o gênero",
        social_name: "Insira seu nome social",
        artistic_name: "Insira seu nome artístico",
        rg: "RG",
        phone: "Insira seu telefone",
        whatsapp: "Insira seu Whatsapp",
        instagram: "Insira seu Instagram",
        description: "Descreva",
    },
    gender: {
        woman: "Feminino",
        man: "Masculino",
        empty: "Prefiro não dizer",
    }
  };
  