import store from "@/store";
import { validToken } from "../api/rest/users";
import { decrypt, encrypt } from "../utils/crypt";
import { goToLink, checkRouter } from "../utils/link";
import type { UserType, UserProfileType } from "../schemas";

export const setUser = (data: UserType) => {
  return new Promise((resolve, reject) => {
    try {
      const session = process.env.VUE_APP_USER_SESSION ?? "";
      const encryptData = encrypt(JSON.stringify(data));

      localStorage.setItem(session, encryptData ?? "");
      store.commit("SET_USER", data);
      store.commit("SET_LOGGED", true);
      resolve(true);
    } catch (_) {
      reject(false);
    }
  });
};

export const setProfile = (data: UserProfileType) => {
  return new Promise((resolve, reject) => {
    try {
      store.commit("SET_PROFILE", data);
      resolve(true);
    } catch (_) {
      reject(false);
    }
  });
};

export const logOutUser = () => {
  store.commit("SET_LOADING", true);
  const session = process.env.VUE_APP_USER_SESSION ?? "";
  localStorage.removeItem(session);
  store.commit("SET_USER", null);
  store.commit("SET_AVATAR", null);
  store.commit("SET_PROFILE", null);
  store.commit("SET_LOGGED", false);
  store.commit("SET_LOADING", false);

  if (checkRouter("recover") || checkRouter("register"))
    return;

  goToLink(`/login`);
}

export function getUser(): UserType | null {
  try {
    const session = process.env.VUE_APP_USER_SESSION ?? "";
    const data = localStorage.getItem(session);
    const decrypted = decrypt(data);
    if (decrypted) {
      const dataParse: UserType = JSON.parse(decrypted);
      if (dataParse.token) {
        return dataParse;
      }
    }
    return null;
  } catch (_) {
    logOutUser();
    return null;
  }
}

export async function tokenIsValid() {
  const check = await validToken();
  if (!check.success)
    logOutUser();
  return check.success;
}
