<script setup lang="ts">
import store from "@/store";
import { ref, onMounted } from "vue";
// import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

import { getDate, getHour, getWeek } from "@/common/utils/dates";
import { toast } from "@/common/utils/toast";

import { BaseConfirm } from "@/components";
import concurseForm from "./components/concurse-form.vue";

import { candidateGet, candidatePut } from "@/common/api/rest/candidates";

import type { PayloadType } from "./components/concurse-form.vue";
import type { CandidateType } from "@/common/api/rest/candidates";

// const { t, tm } = useI18n();

const route = useRoute();
const router = useRouter();

const payload = ref<PayloadType>();
const images = ref<string[]>([]);

const concurse = ref<CandidateType>();

const modalQuit = ref(false);
const showTerms = ref(false);

function fetchCandidate() {
    candidateGet(store.getters.user.id)
        .then((response) => {
            const { success, data } = response;

            if (success) {
                data.forEach((item: CandidateType) => {
                    if (item.candidate_id == route.params.id) {
                        concurse.value = item;

                        payload.value = {
                            name: item.name,
                            personage: item.personage,
                            universe: item.universe,
                            url_mp3: item.url_mp3,
                            url_video: item.url_video,
                            description: item.description,
                        };

                        images.value = [
                            item.avatar_a,
                            item.avatar_b,
                            item.avatar_c,
                            item.avatar_d,
                            item.avatar_e,
                        ];
                    }
                })
            }
        })
        .finally(() => {
            store.commit("SET_LOADING", false);
        });
}

function putCandidate() {
    store.commit("SET_LOADING", true);
    candidatePut(
        concurse.value?.candidate_id as never,
        payload.value as never
    )
        .then((response) => {
            const { success, data } = response;

            if (success) {
                toast({ message: data.message, type: "success" });
                fetchCandidate();
            } else {
                toast({ message: data.message, type: "error" });
            }
        })
        .catch(() => {
            toast({ message: "Erro interno do servidor", type: "error" });
        })
        .finally(() => {
            store.commit("SET_LOADING", false);
        });
}

onMounted(() => {
    store.commit("SET_LOADING", true);
    fetchCandidate();
});
</script>

<template>
    <section class="candidate-page">
        <div class="concurse">
            <div class="number">
                <div class="label">
                    CANDIDATO
                </div>
                <div class="value">
                    {{ concurse?.candidate_number }}
                </div>
            </div>
            <div class="infos">
                <div class="title">
                    {{ concurse?.concurse.name }}
                </div>
                <div class="date">
                    <span>
                        <span class="uppercase">
                            <strong>
                                {{ getWeek(concurse?.concurse.event_date || "") }}
                            </strong>
                        </span>
                        <span> | </span>
                        <span>
                            {{ getDate(concurse?.concurse.event_date || "") }}
                        </span>
                        <span> - </span>
                        <span>
                            {{ getHour(concurse?.concurse.event_date || "") }}
                        </span>
                    </span>
                </div>
                <div class="resume" v-html="concurse?.concurse.description" />
                <div class="actions">
                    <button @click="showTerms = true">
                        REGRAS GERAIS
                    </button>
                </div>
            </div>
        </div>

        <concurse-form
            v-if="payload"
            :payload="payload"
            :images="images"
            :type="concurse?.type"
            @update:values="payload = $event"
            @back="router.push('/concurses')"
            @save:values="putCandidate()"
            @fetch:values="fetchCandidate()"
        />

        <div v-if="showTerms" class="terms">
            <div class="box">
                <iframe
                    src="https://drive.google.com/file/d/1p_P_AaHs-U9GFMuASxMzySA3zQsfN0ZG/preview"
                    allow="autoplay"
                />
                <div class="actions">
                    <button class="refuse" @click="showTerms = false">
                        FECHAR
                    </button>
                </div>
            </div>
        </div>

        <base-confirm
            :show="modalQuit"
            title="DESISTIR DO CONCURSO"
            message="teste"
            @confirm="() => {}"
            @cancel="modalQuit = false"
        />
    </section>
</template>

<style lang="scss" scoped>
.candidate-page {
    display: flex;
    min-height: 63vh;
    flex-direction: column;
    padding-block: 2rem;

    .concurse {
        display: flex;
        flex-direction: row;
        gap: 30px;
        border: 1px solid black;
        padding-block: 20px;
        padding-inline: 20px;
        margin-block-end: 30px;
        border-radius: 8px;

        .infos {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 15px;

            .title {
                font-size: 20px;
                font-weight: bold;
                text-transform: uppercase;
            }
        }

        .number {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: fit-content;
            height: fit-content;
            gap: 5px;
            background: var(--primary);
            padding-inline: 20px;
            padding-block: 20px;
            border-radius: 8px;

            .label {
                font-size: 10px;
                line-height: 10px;
                font-weight: bold;
                text-align: center;
            }

            .value {
                font-size: 70px;
                line-height: 70px;
                font-weight: bold;
            }
        }

        .actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: auto;
            gap: 20px;
            margin-block-start: 15px;

            button {
                cursor: pointer;
                height: 50px;
                border: 1px solid transparent;
                padding-inline: 15px;
                background: #181818;
                color: #fff;
                font-weight: 600;
                border-radius: 8px;

                &:hover {
                    background: black;
                }

                &:disabled {
                    cursor: default;
                    background: #e8e8e8;
                    border: 0;
                }
            }
        }
    }

    .terms {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        background: #0000009f;

        .box {
            width: 70%;
            height: 550px;
            margin-inline: auto;
            background: white;
            border-radius: 8px;

            iframe {
                width: 99.5%;
                height: calc(100% - 70px);
                margin-block-end: 8px;
            }

            .actions {
                display: flex;
                flex-direction: row;
                height: 35px;
                gap: 15px;
                justify-content: center;
                padding-inline: 8px;

                button {
                    cursor: pointer;
                    border: 0;
                    border-radius: 8px;
                    padding-inline: 10px;
                    background: #dbdbdb;
                    font-weight: bold;

                    &:hover {
                        background: #afafaf;
                    }
                }

                .accept {
                    background: var(--primary);
                    
                    &:hover {
                        background: var(--primary-hover);
                    }

                    &:active {
                        background: var(--primary-active);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .candidate-page {
        .concurse {
            flex-direction: column;
        }
    }
}
</style>
