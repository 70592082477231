import { useMutation } from "@tanstack/vue-query";
import { credentialAdd, credentialGet } from "../api/rest/credentials";
import type { CredentialType } from "@/common/schemas";

export function useAddCredential() {
    return useMutation({
      mutationKey: ["credential", "add"],
      mutationFn: async (data: { id: string; payload: CredentialType }) =>
        await credentialAdd(data.id, data.payload),
    });
}

export function useFetchCredential() {
    return useMutation({
      mutationKey: ["credential"],
      mutationFn: async (id: string) => await credentialGet(id),
    });
}