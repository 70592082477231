<script setup lang="ts">
import store from "@/store";
import { ref, onBeforeMount, onMounted } from "vue";

import { getImage } from "@/common/utils/images";
import { getTranslate } from "@/common/utils/texts";
import { toast } from "@/common/utils/toast";
import { goToLink } from "@/common/utils/link";
import { BaseInput, BaseButton } from "@/components";

import { getUser, setProfile } from "@/common/services/users";
import { useUserProfile } from "@/common/hooks/users";

import { userFetchAvatar } from "@/common/api/rest/users";

const { mutateAsync: fetchProfile } = useUserProfile();

const user = ref();

function fetchAvatar(id: string) {
    userFetchAvatar(id)
        .then((response) => {
            if (response.success) {
                store.commit("SET_AVATAR", response.data.file);
            }
        });
}

onBeforeMount(() => {
  user.value = getUser();
});

onMounted(() => {
  if (user.value)
    fetchProfile(user.value.id)
      .then((result) => {
        const { success, data } = result;
        if (success) {
          setProfile(data.profile);
          fetchAvatar(data.id);
        }
      })
      .catch(() => {
        toast({ message: "Erro ao carregar o perfil", type: "error" });
      });
});
</script>

<template>
  <section class="home-page">
    <h1>
      {{ getTranslate({ text: "common.texts.welcome" }) }}
    </h1>
    <div class="group">
      <div>

      </div>
      <img :src="getImage({ image: 'images/mascot/ace.png' })" />
    </div>
    <!-- <img :src="getImage({ image: 'images/mascot/feme.png' })" /> -->
  </section>
</template>

<style lang="scss" scoped>
.home-page {
  display: flex;
  min-height: 61vh;
  flex-direction: column;
  padding-block: 2rem;

  .group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-block: 1rem;

    img {
      width: 260px;
    }
  }
}

@media screen and (max-width: 800px) {
}
</style>
