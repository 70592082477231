export const common = {
    languages: {
      pt_br: "pt-br",
      en_us: "en-us",
    },
    labels: {
      advance: "Avançar",
      back: "Voltar",
      send: "Enviar",
      save: "Salvar",
      enter: "Entrar",
      home: "Home",
      apply: "Aplicar",
      gift_code: "Gift code",
      donations: "Doações",
      donation: "Doação",
      donate: "Quero doar",
      to_donate: "Doar",
      account: "Conta",
      rewards: "Recompensas",
      reward: "Recompensa",
      concurses: "Concursos",
      my_account: "Minha Conta",
      my_gifts: "Meus Gifts",
      my_rewards: "Minhas recompensas",
      my_donations: "Minhas doações",
      my_donation: "Minha doação",
      pending_donations: "Doações pendendes",
      configs: "Configurações",
      rescue: "Resgatar",
      exit: "Sair",
      code: "Código",
      name: "Nome",
      description: "Descrição",
      earn: "Ganhe",
      validty: "Validade",
      level: "Level",
      character: "Personagem",
      characters: "Personagens",
      invoices: "Faturas",
      status: "Situação",
      expiration: "Vencimento",
      open: "Abrir",
      credentials: "Credenciais",
      select: "Selecione...",
    },
    status: {
      pending: "Pendente",
      cancelled: "Cancelada",
      approved: "Aprovada",
    },
    texts: {
      empty_list: "Não há itens para serem exibidos.",
      select_person: "Selecione o personagem que receberá a recompensa:",
      help_the_server: "Ajude a manter o servidor",
      win_rewards: "Faça sua doação e ganhe recompensas",
      select_reward: "Escolha sua recompensa",
      chose_reward: "Escolha abaixo qual recompensa deseja ganhar!",
      select_value: "Escolha abaixo o valor que deseja doar:",
      confirm_donate: "Confirma a doação de {price} para o servidor!",
      confirm_reward: "Você ganhará {item} como recompensa",
      copy_code: "Clique para copiar o código",
      copied: "Código copiado",
      copied_error: "Erro ao copiar código",
      welcome: "Olá estrelinha, seja bem vindo!",
    }
  };
  