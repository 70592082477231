import moment from "moment";

export function getHour(date: string | null, sec = true) {
    if (!date)
        return "--:--";

    const formattedDate = moment(date);
    const format = sec ? 'HH:mm:ss' : 'HH:mm';

    return formattedDate.format(format);
}

export function getDate(date: string | null, response = "--/--/----") {
    if (!date)
        return response;

    const formattedDate = moment(date).format('DD/MM/YYYY');

    return formattedDate;
}

export function getDateISO(date: string) {
    if (!date)
        return "";

    const formattedDate = moment(date, "DD/MM/YYYY").format('YYYY-MM-DDTHH:mm:ss');

    return formattedDate;
}

export function getWeek(date: string | null) {
    if (!date)
        return "";

    const formattedDate = moment(date);
    const dayOfWeek = formattedDate.format('dddd');

    return dayOfWeek.toLowerCase();
}

export function maskDateBR(text: string) {
    let formatted = text.replace(/\D/g, '');
    if (formatted.length > 2)
        formatted = formatted.replace(/^(\d{2})(\d)/, '$1/$2');

    if (formatted.length > 5)
        formatted = formatted.replace(/^(\d{2})\/(\d{2})(\d{1,4})/, '$1/$2/$3');

    return formatted;
}