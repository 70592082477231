<script setup lang="ts">
import { useRoute } from "vue-router";
import { ref, computed, watchEffect, onMounted, onBeforeUnmount } from "vue";
import { getImage } from "@/common/utils/images";
import { getTranslate } from "@/common/utils/texts";
import { goToLink } from "@/common/utils/link";
import { toast } from "@/common/utils/toast";
import { config } from "@/config";
import { getRandomNumber } from "@/common/utils/common";
import { BaseInput, BaseButton } from "@/components";
import { onBeforeRouteLeave } from "vue-router";
import store from "@/store";

import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";

import { useUserRecoverHash, useUserUpdatePass } from "@/common/hooks/users";

const route = useRoute();

const { mutateAsync: userCheckHash, isLoading: loadingHash } = useUserRecoverHash();
const { mutateAsync: updatePassword, isLoading: loadingPass } = useUserUpdatePass();

const interval = ref();
const valid = ref(false);
const showPassword = ref(false);
const showConfirmPassword = ref(false);

const loading = computed(() => loadingPass.value || loadingHash.value);

const password = ref("");
const confirmPass = ref("");

const errors = ref<{ [key: string]: string }[]>([]);

const rules = {
  password: { required, minLength: minLength(6) },
  confirmPass: { required, minLength: minLength(6) },
};


const validate = useVuelidate(rules, { password, confirmPass });

const hash = computed(() => route.params.hash as string);

function togglePassword() {
  showPassword.value = !showPassword.value;
}

function toggleConfirmPassword() {
    showConfirmPassword.value = !showConfirmPassword.value;
}

function isValid() {
  errors.value = [];
  if (validate.value.password.$invalid) {
    const length = password.value?.length | 0;
    const type = length > 0 && length < 2 ? "min" : "required";
    errors.value.push({ password: type });
  }
  if (validate.value.confirmPass.$invalid) {
    const length = confirmPass.value?.length | 0;
    const type = length > 0 && length < 8 ? "min" : "required";
    errors.value.push({ confirmPass: type });
  }

  if (password.value !== confirmPass.value) {
    errors.value.push({ confirmPass: "not_equal" });
  }

  return !validate.value.$invalid;
}

function messages(type: string, lenght: number) {
  const types = {
    required: getTranslate("error.required"),
    min: getTranslate({
      text: "error.min",
      concat: { min: lenght },
    }),
  };

  return types[type as never];
}

function checkError(key: string) {
  const error = errors.value.filter((item) => Object.keys(item).includes(key));
  return error?.length > 0;
}

function errorMessage(key: string) {
  const [error] = errors.value.filter((item) =>
    Object.keys(item).includes(key)
  );

  if (!error) return;

  return messages(error[key], key === "login" ? 2 : 8);
}

function removeError() {
  errors.value = [];
}

function checkHash() {
    userCheckHash(hash.value, {
        onSuccess: (response) => {
            const { success, data } = response;

            if (success) {
                valid.value = true;
            } else {
                toast({
                    message: data.message,
                    type: "error",
                });
                setTimeout(() => {
                    goToLink("/login");
                }, 2000);
            }
        },
        onError: () => {
            toast({
                message: getTranslate({ text: "error.bad_request" }),
                type: "error",
            });
        }
    });
}

function sendPassword() {
    if (!isValid()) return;

    updatePassword({
        hash: hash.value,
        password: password.value
    }, {
        onSuccess: (response) => {
            const { success, data } = response;

            if (success) {
                toast({
                    message: data.message,
                    type: "success",
                });
                setTimeout(() => {
                    goToLink("/login");
                }, 2000);
            } else {
                toast({
                    message: data.message,
                    type: "error",
                });
            }
        },
        onError: () => {
            toast({
                message: getTranslate({ text: "error.bad_request" }),
                type: "error",
            });
        }
    });
}

function changeBackgroundImage() {
    const randomImages = [
        getImage({ image: "images/banner1.jpg" }),
        getImage({ image: "images/banner2.jpg" }),
        getImage({ image: "images/banner3.jpg" }),
    ];

    const backgroundIndex = getRandomNumber(0, randomImages?.length);
    const [page] = document.getElementsByClassName("login-page");
    if (page) {
        const backgroundImage = `url(${randomImages[backgroundIndex]})`;
        page.style.setProperty("background-image", backgroundImage);
    }
}

onBeforeUnmount(() => {
    clearInterval(interval.value);
});

onBeforeRouteLeave(() => {
    clearInterval(interval.value);
});

watchEffect(() => {
    store.commit("SET_LOADING", loading.value);
});

onMounted(() => {
    interval.value = setInterval(changeBackgroundImage, 10000);

    if (hash.value)
        checkHash();
});
</script>

<template>
    <section class="login-page">
        <div class="box-login shadow">
            <div class="box-login__left">
                <div>
                    <img :src="getImage({ image: config.logotipo.primary_white })" />
                </div>
            </div>

            <div class="box-login__right">
                <div class="title">
                    {{ getTranslate({ text: "login.titles.new_password" }) }}
                </div>
                <form v-if="valid" class="form" @submit.prevent="sendPassword()">
                    <BaseInput
                        name="password"
                        :sufix="true"
                        :sufix-click="true"
                        :type="showPassword ? 'text' : 'password'"
                        :value="password"
                        :placeholder="getTranslate({ text: 'login.placeholders.password' })"
                        color="#000"
                        background="#fff"
                        :error="checkError('password')"
                        :error-message="errorMessage('password')"
                        @update:value="(password = $event), removeError()"
                        @click:sufix="togglePassword()"
                    >
                        <template v-slot:sufix>
                        <img
                            :src="
                            getImage({
                                image: showPassword
                                ? 'icons/black/lock-open.png'
                                : 'icons/black/lock-closed.png',
                            })
                            "
                        />
                        </template>
                    </BaseInput>

                    <BaseInput
                        name="confirmPass"
                        :sufix="true"
                        :sufix-click="true"
                        :type="showConfirmPassword ? 'text' : 'password'"
                        :value="confirmPass"
                        :placeholder="getTranslate({ text: 'login.placeholders.confirm_password' })"
                        color="#000"
                        background="#fff"
                        :error="checkError('confirmPass')"
                        :error-message="errorMessage('confirmPass')"
                        @update:value="(confirmPass = $event), removeError()"
                        @click:sufix="toggleConfirmPassword()"
                    >
                        <template v-slot:sufix>
                        <img
                            :src="
                            getImage({
                                image: showConfirmPassword
                                ? 'icons/black/lock-open.png'
                                : 'icons/black/lock-closed.png',
                            })
                            "
                        />
                        </template>
                    </BaseInput>

                    <div class="form__actions">
                        <BaseButton
                            width="70%"
                            background="#F29826"
                            background-active="#fda22a"
                            color="black"
                            color-active="black"
                            dotted-color="black"
                            dotted-color-active="black"
                            :uppercase="true"
                            :bold="true"
                            :dotted="false"
                        >
                            {{ getTranslate({ text: "common.labels.send" }) }}
                        </BaseButton>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
$box-radius: 0.625rem;
$box-height: 30rem;

.login-page::before {
    opacity: 1;
    transition: opacity 1s 0;
}

.login-page::after {
    opacity: 0.5;
    transition: opacity 1s ease;
}

.login-page {
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    background: var(--white-off);
    background-image: url("@/assets/images/banner1.jpg");
    background-size: cover;
    background-position: center;

    .title {
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
    }

    .box-login {
        display: grid;
        grid-template-columns: 13.625rem auto;
        width: 38rem;
        min-height: $box-height;
        flex-direction: row;
        border-radius: $box-radius;

        &__left {
            display: flex;
            min-height: $box-height;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            background: #0d0f53d3;
            border-top-left-radius: $box-radius;
            border-bottom-left-radius: $box-radius;

            img {
                width: 10rem;
            }
        }

        &__right {
            display: flex;
            min-height: $box-height;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: var(--white-opacity);
            border-top-right-radius: $box-radius;
            border-bottom-right-radius: $box-radius;
            gap: 1.5rem;

            .form {
                display: flex;
                width: calc(100% - 8rem);
                flex-direction: column;
                gap: 1rem;

                &__actions {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 1.5rem;
                    margin-block-start: 0.5rem;

                    .recover {
                        cursor: pointer;
                        font-size: 1rem;
                        font-weight: bold;
                    }

                    .register {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 10px;
                        cursor: pointer;
                        font-size: 1rem;
                        margin-block-start: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .login-page {
        align-items: center;
        justify-content: flex-start;

        .box-login {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;

            &__left {
                width: 100%;
                min-width: 100%;
                height: 13.625rem;
                min-height: 13.625rem;
                max-height: 13.625rem;
                flex-direction: row;
                border-radius: 0;
            }

            &__right {
                width: 100%;
                height: 100%;
                border-radius: 0;

                .form {
                    width: 80%;
                }
            }
        }
    }
}
</style>
