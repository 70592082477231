import { accessibility } from "./accessibility";
import { error } from "./error";
import { common } from "./common";
import { login } from "./login";
import { gift } from "./gift";
import { user } from "./user";
import { credentials } from "./credentials";
import { concurses } from "./concurses";

export const language = {
  translations: {
    accessibility,
    common,
    login,
    error,
    gift,
    user,
    credentials,
    concurses,
  },
};
