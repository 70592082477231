<script setup lang="ts">
import { orderBy } from "lodash-es";
import store from "@/store";
import { ref, onMounted } from "vue";
// import { useI18n } from "vue-i18n";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";

import { getDate, getHour, getWeek } from "@/common/utils/dates";
import { toast } from "@/common/utils/toast";

import { BaseConfirm, BaseInput } from "@/components";
import concurseForm from "./components/concurse-form.vue";

import { concurseGet, concurseStatus, concurseResult } from "@/common/api/rest/concurses";
import { candidatesList, candidatesCheckin } from "@/common/api/rest/jurors";
import { fetchCandidateImage } from "@/common/api/rest/files";
import { questionsList, questionsResponse } from "@/common/api/rest/questions";


const route = useRoute();
const router = useRouter();

const timer = ref();

const step = ref(0);

const concurse = ref();
const questions = ref();
const responses = ref();

const candidates = ref();
const candidate = ref();

const results = ref([]);

const images = ref([]);
const imageShow = ref(-1);
const imagesUrls = ref<string[]>([]);

const search = ref("");
const seachinng = ref();
const searched = ref([]);

const modalEvaluate = ref(false);
const modalFinish = ref(false);
const showTerms = ref(false);

function fetchConcurse() {
    concurseGet(route.params.id as string)
        .then((response) => {
            const { success, data } = response;

            if (success) {
                concurse.value = data;
                fetchCandidates();
                fetchQuestions();
                fetchResult();
            } else {
                toast({ message: data.message, type: "error" });
            }
        })
        .catch(() => {
            toast({ message: "Erro interno do servidor", type: "error" });
        })
        .finally(() => {
            store.commit("SET_LOADING", false);
        });
}

function fetchResult() {
    concurseResult(route.params.id as string)
        .then((response) => {
            const { success, data } = response;

            if (success) {
                const format = [];
                Object.values(data).forEach((member: any) => {
                    const scores = Object.values(member.score); // Extrai os valores dos scores
                    const cappedScores = scores.map(score => Math.min(score, 10)); // Substitui valores maiores que 10 por 10
                    const sum = cappedScores.reduce((acc, curr) => acc + curr, 0); // Soma os valores dos scores limitados
                    const average = cappedScores.length > 0 ? sum / cappedScores.length : 0; 

                    format.push({
                        member_id: member.member_id,
                        name: member.name,
                        personage: member?.personage?.substring(0, 20),
                        universe: member.universe?.substring(0, 20),
                        score: average?.toFixed(3),
                    });
                });
                format.sort((a, b) => b.score - a.score);
                results.value = format;
            } else {
                toast({ message: data.message, type: "error" });
            }
        })
        .catch((error) => {
            console.log("erorr", error)
            toast({ message: "Erro interno do servidor", type: "error" });
        })
        .finally(() => {
            store.commit("SET_LOADING", false);
        });
}

function fetchCandidates() {
    candidatesList(route.params.id as string)
        .then((response) => {
            const { success, data } = response;

            if (success) {
                candidates.value = [];
                data?.forEach((item) => {
                    if (concurse.value.status === "pending") {
                        candidates.value.push(item);
                    }

                    if (concurse.value.status === "start") {
                        if (item.status === "pending") {
                            candidates.value.push(item);
                        }
                    }
                });

                candidates.value = orderBy(candidates.value, "candidate_number");
            } else {
                toast({ message: data.message, type: "error" });
            }
        })
        .catch((error) => {
            console.log("error", error)
            toast({ message: "Não foi possível carregar a lista de candidatos", type: "error" });
        })
        .finally(() => {
            store.commit("SET_LOADING", false);
        });
}

function fetchAvatar() {
    images.value.forEach(async (id, index) => {
        await fetchCandidateImage(id, "large")
            .then((response) => {
                const { success, data } = response;
                if (success) {
                    imagesUrls.value[index] = data.file;
                }
            });
    });
}

function fetchQuestions() {
    questionsList(concurse.value?.concurse_id)
        .then((response) => {
                const { success, data } = response;
                if (success) {
                    questions.value = data;
                }
            });
}

function startConcurse () {
    store.commit("SET_LOADING", true);
    concurseStatus(concurse.value?.concurse_id, "start")
        .then((response) => {
            const { success, data } = response;
            if (success) {
                reloadData();
            } else {
                toast({ message: data.message, type: "error" });
            }
        })
        .catch(() => {
            toast({ message: "Não foi possível iniciar o concurso", type: "error" });
        })
        .finally(() => {
            store.commit("SET_LOADING", false);
        });
}

function finishConcurse () {
    modalFinish.value = false;
    store.commit("SET_LOADING", true);
    concurseStatus(concurse.value?.concurse_id, "finished")
        .then((response) => {
            const { success, data } = response;
            if (success) {
                reloadData();
            } else {
                toast({ message: data.message, type: "error" });
            }
        })
        .catch(() => {
            toast({ message: "Não foi possível iniciar o concurso", type: "error" });
        })
        .finally(() => {
            store.commit("SET_LOADING", false);
        });
}

function showImage(index: number) {
    if (imagesUrls.value[index])
        imageShow.value = index;
}

function evaluateCandidate(item: any) {
    candidate.value = item;
    images.value = [
        candidate.value.avatar_a,
        candidate.value.avatar_b,
        candidate.value.avatar_c,
        candidate.value.avatar_d,
        candidate.value.avatar_e,
    ];

    responses.value = {};
    questions.value.forEach((item) => {
        responses.value[item.question_id] = {
            question_id: item.question_id,
            concurse_id: concurse.value.concurse_id,
            member_id: candidate.value.member_id,
            user_id: store.getters.user.id,
            name: candidate.value.name,
            score: 0,
            description: ""
        };
    });

    step.value = 1;
    fetchAvatar();
}

function finish() {
    modalFinish.value = true;
}

function saveEvaluate() {
    store.commit("SET_LOADING", true);
    modalEvaluate.value = false;
    Object.entries(responses.value)
        .forEach(async ([key, value], index) => {
            await questionsResponse(value);        
        });

    candidate.value = null;
    responses.value = {};
    step.value = 0;
    reloadData();
}

function reloadData() {
    fetchConcurse();
}

function checkCandidate() {
    if (!candidate.value)
        return;

    step.value = 1;
}

function checkInCandidate(id: string) {
    store.commit("SET_LOADING", true);
    candidatesCheckin(id)
        .then((response) => {
            const { success, data } = response;

            if (success) {
                reloadData();
            } else {
                toast({ message: data.message, type: "error" });
            }
        })
        .catch(() => {
            toast({ message: "Não foi possível carregar a lista de candidatos", type: "error" });
        })
        .finally(() => {
            store.commit("SET_LOADING", false);
        });
}

function searchValue(value: string) {
    seachinng.value = (value?.length || 0) > 0;
    searched.value = [];

    candidates.value?.forEach((item: any) => {
        console.log("number", value, item.candidate_number, value);
        if (!isNaN(Number(value))) {
            console.log("number", value, item.candidate_number);
            if (parseInt(item.candidate_number) === parseInt(value))
                searched.value.push(item);
        } else {
            if (item?.name?.includes(value))
                searched.value.push(item);
        }
    });
}

function viewResult() {
    fetchResult();
    candidate.value = null;
    responses.value = {};
    step.value = 2;
}

onBeforeRouteLeave(() => {
    clearInterval(timer.value);
})

onMounted(() => {
    store.commit("SET_LOADING", true);
    fetchConcurse();

    timer.value = setInterval(() => {
        fetchConcurse();
    }, 10000);
});
</script>

<template>
    <section class="candidate-page">
        <div class="concurse">
            <div class="infos">
                <div class="title">
                    {{ concurse?.name }}
                </div>
                <div class="date">
                    <span>
                        <span class="uppercase">
                            <strong>
                                {{ getWeek(concurse?.event_date || "") }}
                            </strong>
                        </span>
                        <span> | </span>
                        <span>
                            {{ getDate(concurse?.event_date || "") }}
                        </span>
                        <span> - </span>
                        <span>
                            {{ getHour(concurse?.event_date || "") }}
                        </span>
                    </span>
                </div>
                <div class="resume" v-html="concurse?.description" />
                <div class="actions">
                    <template v-if="concurse?.status === 'pending'">
                        <button class="primary" @click="startConcurse()">
                            INICIAR CONCURSO
                        </button>
                    </template>
                    <template v-else-if="concurse?.status === 'start'">
                        <button @click="finish()">
                            FINALIZAR CONCURSO
                        </button>
                    </template>
                    <template v-else>
                        <button disabled>
                            FINALIZADO
                        </button>
                    </template>
                </div>
            </div>
        </div>

        <section class="tabs">
            <div class="item" :class="{ active: step === 0 }" @click="step = 0">
                CANDIDATOS
            </div>
            <div
                class="item"
                v-if="concurse?.status === 'start'"
                :class="{ active: step === 1 }"
                @click="checkCandidate()"
            >
                AVALIAÇÃO
            </div>
            <div
                class="item"
                v-if="concurse?.status === 'start' || concurse?.status === 'finished'"
                :class="{ active: step === 2 }"
                @click="viewResult()"
            >
                RESULTADO
            </div>
        </section>

        <template v-if="step === 0">
            <section class="candidates">
                <base-input
                    name="search"
                    :value="search"
                    :sufix="true"
                    :sufix-click="true"
                    placeholder="Buscar"
                    color="#000"
                    background="#fff"
                    @update:value="searchValue($event)"
                >
                    <template v-slot:sufix>
                        <img src="@/assets/icons/black/search.png" />
                    </template>
                </base-input>
                <div class="content">
                    <template
                        v-for="(item, index) in seachinng ? searched : candidates"
                        :key="index"
                    >
                        <div class="item">
                            <div class="infos">
                                <div class="number">
                                    {{ item.candidate_number }}
                                </div>
                                <div class="name">
                                    {{ item.name }}
                                </div>
                            </div>
                            <div class="actions">
                                <button
                                    v-if="concurse?.status === 'pending'"
                                    :disabled="item.check_in"
                                    @click="checkInCandidate(item.candidate_id)"
                                >
                                    CHECK-IN
                                </button>
                                <button
                                    v-if="concurse?.status === 'start'"
                                    class="evaluate"
                                    @click="evaluateCandidate(item)"
                                >
                                    AVALIAR
                                </button>
                            </div>
                        </div>
                    </template>
                </div>
            </section>
        </template>

        <template v-if="step === 1">
            <section v-if="candidate" class="candidates">
                <div class="candidate">
                    <div class="number">
                        <div class="label">
                            CANDIDATO
                        </div>
                        <div class="value">
                            {{ candidate?.candidate_number }}
                        </div>
                    </div>
                    <div class="infos">
                        <div class="line">
                            <label>NOME:</label>
                            <div class="value">
                                {{ candidate.name }}
                            </div>
                        </div>
                        <div class="line">
                            <label>PERSONAGEM:</label>
                            <div class="value">
                                {{ candidate.personage }}
                            </div>
                        </div>
                        <div class="line">
                            <label>UNIVERSO:</label>
                            <div class="value">
                                {{ candidate.universe }}
                            </div>
                        </div>
                        <div class="line">
                            <label>DESCRIÇÃO:</label>
                            <div class="value">
                                {{ candidate.description }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="images">
                    <template v-for="(image, index) in images" :key="index">
                        <div class="image" @click="showImage(index)">
                            <img v-if="imagesUrls[index]" :src="imagesUrls[index]" />
                            <span v-else>
                                <img class="no-image" src="@/assets/icons/black/no-image.png" />
                            </span>
                        </div>
                    </template>
                </div>

                <div class="questions">
                    <h2>AVALIAÇÃO</h2>
                    <template v-for="(quest, index) in questions" :key="index">
                        <div class="question">
                            <div class="header">
                                <div class="title">
                                    {{ quest.name }}
                                </div>
                                <div class="resume">
                                    {{ quest.description }}
                                </div>
                            </div>
                            <div class="score">
                                <label>NOTA</label>
                                <input
                                    type="number"
                                    v-model="responses[quest.question_id].score"
                                    :min="0"
                                    :max="10"
                                    step="0.5"
                                >
                            </div>
                        </div>
                    </template>
                    <div class="actions">
                        <button @click="modalEvaluate = true">
                            SALVAR
                        </button>
                    </div>
                </div>
            </section>
        </template>

        <template v-if="step === 2">
            <section class="candidates">
                <div class="results">
                    <template v-for="(item, index) in results" :key="index">
                        <div class="item">
                            <div class="number">
                                {{ index + 1 }}
                            </div>
                            <div class="infos">
                                <div class="name">
                                    {{ item.name }}
                                </div>
                                <div v-if="item.personage" class="personage">
                                    {{ item.personage }}
                                </div>
                                <div v-if="item.universe" class="personage">
                                    {{ item.universe }}
                                </div>
                            </div>
                            <div class="score">
                                {{ item.score }}
                            </div>
                        </div>
                    </template>
                </div>
            </section>
        </template>

        <section v-if="imageShow >= 0" class="show-image">
            <div class="box">
                <div class="close" @click="imageShow = -1">
                    <img src="@/assets/icons/black/close.png">
                </div>
                <img class="image" :src="imagesUrls[imageShow]" />
            </div>
        </section>

        <base-confirm
            :show="modalEvaluate"
            title="AVALIAR CANDIDATO"
            message="Deseja salvar a AVALIAÇÃO deste candidato?"
            @confirm="saveEvaluate()"
            @cancel="modalEvaluate = false"
        />

        <base-confirm
            :show="modalFinish"
            title="FINALIZAR CONCURSO"
            message="Deseja FINALIZAR o concurso?"
            @confirm="finishConcurse()"
            @cancel="modalFinish = false"
        />
    </section>
</template>

<style lang="scss" scoped>
.candidate-page {
    display: flex;
    min-height: 63vh;
    flex-direction: column;
    padding-block: 2rem;

    .concurse {
        display: flex;
        flex-direction: row;
        gap: 30px;
        border: 1px solid black;
        padding-block: 20px;
        padding-inline: 20px;
        margin-block-end: 30px;
        border-radius: 8px;

        .infos {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 15px;

            .title {
                font-size: 20px;
                font-weight: bold;
                text-transform: uppercase;
            }
        }

        .actions {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            margin-left: auto;
            gap: 20px;
            margin-block-start: 15px;

            button {
                cursor: pointer;
                height: 50px;
                border: 1px solid transparent;
                padding-inline: 15px;
                background: #181818;
                color: #fff;
                font-weight: 600;
                border-radius: 8px;

                &:hover {
                    background: black;
                }

                &:disabled {
                    cursor: default;
                    background: #e8e8e8;
                    color: #3f3f3f;
                    border: 0;
                }
            }

            button.primary {
                background: var(--primary);
                color: #000;
            }
        }
    }

    .tabs {
        display: flex;
        flex-direction: row;
        gap: 2px;

        .item {
            cursor: pointer;
            user-select: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #e8e8e8;
            font-size: 17px;
            padding-block: 8px;
            padding-inline: 15px;
            font-weight: bold;
            color: #3f3f3f;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            &.active {
                background: #000;
                color: #fff;
            }
        }
    }

    .candidates {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border: 1px solid #000;
        gap: 15px;

        .content {
            display: flex;
            flex-direction: column;
            height: 400px;
            overflow-y: auto;
            gap: 10px;
            border-block-start: 1px solid black;
            margin-block-start: 15px;
            padding-block: 10px;

            .item {
                display: flex;
                flex-direction: row;
                gap: 10px;
                border-block-end: 1px solid #3f3f3f;
                padding-block-end: 10px;
                padding-inline: 10px;

                .infos {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                }

                .number {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: var(--primary);
                    width: 40px;
                    height: 40px;
                    min-width: 40px;
                }

                .name {
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .actions {
                    margin-inline-start: auto;
                    margin-inline-end: 5px;
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    button {
                        cursor: pointer;
                        user-select: none;
                        background: #000;
                        color: #fff;
                        border: 0;
                        font-size: 13px;
                        padding-block: 10px;
                        padding-inline: 10px;

                        &:disabled {
                            background: #e8e8e8;
                            color: #3f3f3f;
                        }
                    }

                    button.evaluate {
                        background: var(--primary);
                        color: #000;
                        font-weight: bold;

                        &:disabled {
                            cursor: default;
                            background: #e8e8e8;
                            color: #3f3f3f;
                        }
                    }
                }
            }
        }


        .images {
            display: flex;
            flex-wrap: wrap;
            padding-block: 20px;
            padding-inline: 10px;
            border-radius: 8px;
            justify-content: space-around;
            gap: 15px;
            background: #e8e8e8;

            .image {
                cursor: pointer;
                user-select: none;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 130px;
                height: auto;
                min-width: 130px;
                max-height: 130px;
                min-height: 130px;
                background: white;
                overflow: hidden;

                .no-image {
                    width: 70px;
                    height: 70px;
                }
            }
        }

        .candidate {
            display: flex;
            flex-direction: row;
            gap: 15px;
        }

        .number {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: fit-content;
            height: fit-content;
            gap: 5px;
            background: var(--primary);
            padding-inline: 10px;
            padding-block: 10px;
            border-radius: 8px;

            .label {
                font-size: 10px;
                line-height: 10px;
                font-weight: bold;
                text-align: center;
            }

            .value {
                font-size: 70px;
                line-height: 70px;
                font-weight: bold;
            }
        }

        .line {
            display: flex;
            flex-direction: column;
            gap: 2px;
            margin-block-end: 10px;

            label {
                font-size: 12px;
                line-height: 12px;
            }

            .value {
                font-size: 16px;
                font-weight: bold;
            }
        }
    }

    .questions {
        display: flex;
        flex-direction: column;
        padding: 20px;

        .question {
            display: flex;
            flex-direction: row;
            gap: 20px;
            padding-block-start: 15px;
            margin-block-start: 15px;
            border-block-start: 1px solid black;

            .header {
                flex: 3;
                display: flex;
                flex-direction: column;
                gap: 5px;

                .title {
                    font-weight: bold;

                }
            }

            .score {
                width: 70px;

                label {
                    width: 100%;
                    font-size: 13px;
                    font-weight: bold;
                    text-align: center;
                }

                input {
                    width: 50px;
                    height: 30px;
                    padding-inline: 8px;
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }

        .actions {
            display: flex;
            flex-direction: column;
            margin-block-start: 50px;

            button {
                    cursor: pointer;
                    user-select: none;
                    height: 60px;
                    background: var(--primary);
                    color: #000;
                    border: 0;
                    font-size: 18px;
                    padding-block: 10px;
                    padding-inline: 10px;
                    font-weight: bold;

                    &:disabled {
                        background: #e8e8e8;
                        color: #3f3f3f;
                    }
                }
        }
    }

    .results {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            border-block-end: 1px solid rgb(122, 122, 122);
            padding-block-end: 7px;

            .infos {
                flex: 1;
                display: flex;
                flex-direction: row;
            }

            .name, .personage {
                flex: 1;
                padding-inline: 5px;
            }

            .personage {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .score {
                flex: 1;
                width: 100px;
                margin-inline-start: auto;
                font-size: 18px;
                font-weight: bold;
                text-align: right;
            }
        }
    }

    .show-image {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background: #00000096;

        .box {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 600px;
            padding: 10px;
            background: white;

            .image {
                width: auto;
                height: 100%;
                max-width: 100%;
                max-height: 500px;
            }

            .close {
                width: 100%;
                display: flex;
                justify-content: flex-end;

                img {
                    user-select: none;
                    width: 30px;
                    margin-block-end: 8px;
                    cursor: pointer;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .candidate-page {
        .concurse {
            flex-direction: column;

            .infos .actions button {
                font-size: 12px;
            }
        }

        .tabs {
            .item {
                font-size: 12px;
            }
        }

        .candidates {
            .content {
                .item {
                    flex-direction: column;
                    gap: 15px;

                    .infos {
                        font-size: 18px;

                        .number {
                            width: 60px;
                            height: 40px;
                            font-size: 20px;
                            font-weight: bold;
                        }
                    }

                    .actions {
                        button {
                            height: 35px;
                            font-size: 10px;
                        }

                        button.evaluate {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .show-image {
            .box {
                width: 90%;

                .image {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    // max-height: 500px;
                }

                .close {
                    img {
                        width: 30px;
                    }
                }
            }
        }

        .results {
            .item .infos {
                flex-direction: column;

                .name {
                    font-weight: bold;
                }
            }
        }
    }

}
</style>
