<script setup lang="ts">
import { ref, computed, watchEffect } from "vue";
import { UserType, UserProfileType } from "@/common/schemas";
import { getTranslate } from "@/common/utils/texts";
import { logOutUser } from "@/common/services/users";
import { getImage } from "@/common/utils/images";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { goToLink } from "@/common/utils/link";
import { BaseAvatar, SelectMultiple } from "@/components";

import { userFetchAvatar } from "@/common/api/rest/users";

const { locale } = useI18n();

enum MenuProfileOptionsEnum {
  account = "account",
  exit = "exit",
}

const user = ref<UserType | null>(null);
const profile = ref<UserProfileType | null>(null);

const name = computed(() => {
  const { social_name, first_name, last_name } = profile.value ?? {};

  if (social_name)
    return social_name;

  if (first_name)
    return `${first_name} ${last_name}`;

  return "";
});

const menuOpen = ref<string | null>(null);

const menuProfileOptions = computed(() => [
  {
    icon: getImage({ image: "icons/black/shield.png" }),
    label: getTranslate({ text: "common.labels.my_account" }),
    description: getTranslate({ text: "common.labels.my_account" }),
    value: MenuProfileOptionsEnum.account,
  },
  {
    icon: getImage({ image: "icons/black/exit.png" }),
    label: getTranslate({ text: "common.labels.exit" }),
    value: MenuProfileOptionsEnum.exit,
  },
]);

const menuLanguages = computed(() => [
  {
    icon: getImage({ image: "flags/pt-br.jpg" }),
    label: "PT-BR",
    value: "pt-BR"
  },
  {
    icon: getImage({ image: "flags/en-us.jpg" }),
    label: "EN-US",
    value: "en-US"
  },
]);

function fetchAvatar(id: string) {
    userFetchAvatar(id, "small")
        .then((response) => {
            if (response.success) {
                store.commit("SET_AVATAR", response.data.file);
            }
        });
}

function changeOptionsProfile(key: MenuProfileOptionsEnum) {
  const options: { [key: string]: any } = {
    [MenuProfileOptionsEnum.account]: () => goToLink("/profile"),
    [MenuProfileOptionsEnum.exit]: () => logOutUser(),
  };
  if (options[key]) options[key]();
}

function changeOptionsLanguage(key: string) {
  localStorage.setItem("locale", key);
  locale.value = key;
}

function toggleMenu(key: string) {
  menuOpen.value = menuOpen.value !== key ? key : null;
}

watchEffect(() => {
  if (store.getters.user) {
    user.value = store.getters.user;
    fetchAvatar(user.value.id);
  }

  if (store.getters.profile)
    profile.value = store.getters.profile;
});
</script>

<template>
  <header class="base-header">
    <BaseAvatar
      :title="name"
      :subtitle="user?.email"
      :image="store.getters.avatar"
      :loading="avatarLoading"
      @click="goToLink('/profile')"
    />
    <nav v-if="!$store.getters.mobile" class="actions" tabindex="10">
      <SelectMultiple
        :icon="getImage({ image: 'icons/black/world.png' })"
        :options="menuLanguages"
        :opened="menuOpen === 'language'"
        @open="toggleMenu('language')"
        @change="changeOptionsLanguage($event)"
      />
      <SelectMultiple
        :icon="getImage({ image: 'icons/black/person.png' })"
        :options="menuProfileOptions"
        :opened="menuOpen === 'profile'"
        @open="toggleMenu('profile')"
        @change="changeOptionsProfile($event)"
      />
    </nav>
  </header>
</template>

<style lang="scss" scoped>
.base-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-block: 1rem;
  padding-inline: var(--padding-inline);
  border-block-end: 1px solid var(--border);

  .actions {
    display: flex;
    height: min-content;
    flex-direction: row;
    gap: 0.75rem;
    padding-block: 0.25rem;
    padding-inline: 1rem;
    border: 1px solid var(--black-full);
    border-radius: 0.5rem;
  }
}
</style>
