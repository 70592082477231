import { get, post, put, del } from "../rest";
import { getLanguage } from "@/common/utils/texts";
import { getUser } from "@/common/services/users";

export interface CandidatePayloadType {
  concurse_id: string;
	member_id: string;
	name: string;
	personage: string;
	universe: string;
	url_mp3: string;
	url_video: string;
	description: string;
	terms_accept: boolean;
}

export interface CandidateType {
    id: number;
    candidate_id: string;
    concurse_id: string;
    member_id: string;
    candidate_number: number;
    name: string;
    personage: string;
    universe: string;
    url_mp3: string;
    url_video: string;
    description: string;
    terms_accept: boolean;
    created: string;
    updated: string | null;
    avatar_a: string;
    avatar_b: string;
    avatar_c: string;
    avatar_d: string;
    avatar_e: string;
    concurse: {
        concurse_id: string;
        event_date: string;
        type: string;
        name: string;
        status: string;
        description: string;
    }
}

function getHeaders() {
  const { token, id } = getUser() ?? {};

  return {
    "content-type": "application/json",
    "language": getLanguage(),
    "token": token,
    "id": id,
  };
}

export async function candidateList(member_id: string) {
  return await get(`admin/candidates/${member_id}`, getHeaders());
}

export async function candidateAdd(data: CandidatePayloadType) {
  return await post("admin/candidates", data, getHeaders());
}

export async function candidatePut(candidate_id: string, data: CandidatePayloadType) {
  return await put(`admin/candidates/${candidate_id}`, data, getHeaders());
}

export async function candidateGet(member_id: string) {
  return await get(`admin/candidates/${member_id}`, getHeaders());
}

export async function candidateDel(member_id: string) {
  return await del(`admin/candidates/${member_id}`, getHeaders());
}
