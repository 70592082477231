export const common = {
    languages: {
      pt_br: "pt-br",
      en_us: "en-us",
    },
    labels: {
      advance: "Advance",
      back: "Back",
      send: "Send",
      enter: "To enter",
      home: "Home",
      apply: "To apply",
      gift_code: "Gift code",
      donations: "Donations",
      donation: "Donation",
      donate: "Donate",
      to_donate: "Donate",
      account: "Account",
      rewards: "Rewards",
      reward: "Reward",
      concurses: "Competition",
      my_gifts: "My Gifts",
      my_account: "My account",
      my_rewards: "My rewards",
      my_donations: "My donations",
      my_donation: "My donation",
      pending_donations: "Pending donations",
      configs: "Configs",
      rescue: "Resgatar",
      exit: "Exit",
      code: "Code",
      name: "Name",
      description: "Description",
      earn: "Earn",
      validty: "Validty",
      level: "Level",
      character: "Character",
      characters: "Characters",
      invoices: "Invoices",
      status: "Status",
      expiration: "Expiration",
      open: "Open",
      credentials: "Credentials",
    },
    status: {
      pending: "Pending",
      cancelled: "Cancelled",
      approved: "Approved",
    },
    texts: {
      empty_list: "There are no items to display.",
      select_person: "Select the character who will receive the reward:",
      help_the_server: "Help maintain the server",
      win_rewards: "Make your donation and earn rewards",
      select_reward: "Choose your reward",
      chose_reward: "Choose below which reward you want to win!",
      select_value: "Choose the amount you would like to donate below:",
      confirm_donate: "Confirm donating {price} to the server!",
      confirm_reward: "You will get {item} as a reward",
      copy_code: "Click to copy the code",
      copied: "Copied code",
      copied_error: "Error copying code",
      welcome: "Hello little star, welcome!",
    }
  };
  