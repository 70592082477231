export const credentials = {
    labels: {
        my_credentials: "My credentials",
        date: "Date",
        hour: "Hour",
        event: "Event",
        type: "Type",
        status: "Status",
        empty: "You do not have accreditations.",
    },
    types: {
        cosplay: "Cosplay",
        media: "Media",
        influencer: "Influencer",
        shopkeeper: "Shopkeeper",
        artist_alley: "Artist alley",
        jurors: "Jurors",
    },
    status: {
        pending: "Pending",
        approval: "Approved",
        disapproved: "Disapproved",
    },
};
  