export const concurses = {
    labels: {
        my_concurses: "My competitions",
    },
    status: {
        pending: "Pending",
        approval: "Approved",
        disapproved: "Disapproved",
        progress: "In progress",
        canceled: "Canceled",
    },
};
  