import { Module } from "vuex";

const themeModule: Module<any, any> = {
  state() {
    return {
      loading: false,
      mobile: false,
      language: null,
    };
  },
  getters: {
    loading: (state) => state.loading,
    mobile: (state) => state.mobile,
    language: (state) => state.language,
  },
  mutations: {
    SET_LOADING: (state, data) => {
      state.loading = data;
    },
    SET_MOBILE: (state, data) => {
      state.mobile = data;
    },
    SET_LANGUAGE: (state, data) => {
      state.language = data;
    },
  },
};

export default themeModule;
