<script setup lang="ts">
import { computed, ref } from "vue";

interface PropsType {
  year?: number | string;
  name?: string;
}

const props = defineProps<PropsType>();

const now = ref(new Date().getFullYear());

const dateNow = computed(() =>
  (props.year || 0).toString() === now.value.toString()
    ? props.year
    : `${props.year}/${now.value}`
);
</script>

<template>
  <section class="base-footer">
    <div class="copyright">Copyright {{ dateNow }} © {{ name }}</div>
  </section>
</template>

<style lang="scss" scoped>
.base-footer {
  display: flex;
  flex-direction: column;
  padding-block: 1.5rem;
  padding-inline: 1.5rem;
  background: var(--white-off);

  .copyright {
    font-size: 1rem;
  }
}
</style>
