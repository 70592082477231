<script setup lang="ts">
import { ref, watchEffect, onMounted, onBeforeUnmount } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { getImage } from "@/common/utils/images";
import { getTranslate } from "@/common/utils/texts";
import { goToLink } from "@/common/utils/link";
import { config } from "@/config";
import { toast } from "@/common/utils/toast";
import { getRandomNumber } from "@/common/utils/common";
import { maskDateBR } from "@/common/utils/dates";
import { onBeforeRouteLeave } from "vue-router";
import store from "@/store";

import { BaseInput, BaseButton } from "@/components";

import { useUserRegister } from "@/common/hooks/users";

const interval = ref();
const login = ref<string>();
const name = ref<string>();
const birthdate = ref<string>();
const email = ref<string>();
const password = ref<string>();
const confirmPassword = ref<string>();
const errors = ref<{ [key: string]: string }[]>([]);

const showPassword = ref(false);
const showConfirmPassword = ref(false);
const step = ref(0);

const rules = {
  login: { required, minLength: minLength(2) },
  name: { required, minLength: minLength(2) },
  email: { required, minLength: minLength(6) },
  password: { required, minLength: minLength(6) },
  confirmPassword: { required, minLength: minLength(6) },
};

const validate = useVuelidate(rules, {
  login,
  name,
  email,
  password,
  confirmPassword,
});

const { mutateAsync: userRegister, isLoading: loading } = useUserRegister();

function messages(type: string, lenght: number) {
  const types = {
    required: getTranslate("error.required"),
    min: getTranslate({
      text: "error.min",
      concat: { min: lenght },
    }),
  };

  return types[type as never];
}

function togglePassword() {
  showPassword.value = !showPassword.value;
}

function toggleConfirmPassword() {
  showConfirmPassword.value = !showConfirmPassword.value;
}

function isValid() {
  errors.value = [];
  if (validate.value.name.$invalid) {
    const length = name.value?.length | 0;
    const type = length > 0 && length < 2 ? "min" : "required";
    errors.value.push({ name: type });
  }
  if (validate.value.email.$invalid) {
    const length = email.value?.length | 0;
    const type = length > 0 && length < 2 ? "min" : "required";
    errors.value.push({ email: type });
  }
  if (!email.value?.includes("@")) {
    errors.value.push({ email: "invalid" });
  }
  if (step.value === 0)
    return errors.value.length == 0;

  if (validate.value.login.$invalid) {
    const length = login.value?.length | 0;
    const type = length > 0 && length < 2 ? "min" : "required";
    errors.value.push({ login: type });
  }
  if (validate.value.password.$invalid) {
    const length = password.value?.length | 0;
    const type = length > 0 && length < 8 ? "min" : "required";
    errors.value.push({ password: type });
  }
  if (validate.value.confirmPassword.$invalid) {
    const length = confirmPassword.value?.length | 0;
    const type = length > 0 && length < 8 ? "min" : "required";
    errors.value.push({ confirmPassword: type });
  }
  if (password.value !== confirmPassword.value) {
    errors.value.push({ confirmPassword: "not_equal" });
  }

  return !validate.value.$invalid;
}

function checkError(key: string) {
  const error = errors.value.filter((item) => Object.keys(item).includes(key));
  return error?.length > 0;
}

function errorMessage(key: string) {
  const [error] = errors.value.filter((item) =>
    Object.keys(item).includes(key)
  );

  if (!error) return;

  return messages(error[key], key === "login" ? 2 : 8);
}

function removeError() {
  errors.value = [];
}

function sendRegister() {
  if (!isValid()) return;
  userRegister(
    {
      login: login.value as string,
      name: name.value as string,
      birthdate: birthdate.value as string,
      email: email.value as string,
      password: password.value as string,
    },
    {
      onSuccess: async (response) => {
        if (response.success) {
          toast({
            message: response?.data?.message,
            type: "success",
          });
          setTimeout(() => {
            goToLink("/login");
          }, 2000);
        } else {
          toast({
            message: response?.data?.message,
            type: "error",
          });
        }
      },
      onError: () => {
        toast({
            message: getTranslate({ text: "error.bad_request" }),
            type: "error",
        });
      }
    }
  );
}

function changeBackgroundImage() {
  const randomImages = [
    getImage({ image: "images/banner1.jpg" }),
    getImage({ image: "images/banner2.jpg" }),
    getImage({ image: "images/banner3.jpg" }),
  ];

  const backgroundIndex = getRandomNumber(0, randomImages?.length);
  const [page] = document.getElementsByClassName("login-page");
  if (page) {
    const backgroundImage = `url(${randomImages[backgroundIndex]})`;
    page.style.setProperty("background-image", backgroundImage);
  }
}

function nextStep() {
  if (isValid())
    step.value += 1;
}

function beforeStep() {
  step.value -= 1;
}



onBeforeUnmount(() => {
  clearInterval(interval.value);
});

onBeforeRouteLeave(() => {
  clearInterval(interval.value);
});

watchEffect(() => {
  store.commit("SET_LOADING", loading.value);

  if (store.getters.logged) goToLink("/home");
});

onMounted(() => {
  interval.value = setInterval(changeBackgroundImage, 10000);
});
</script>

<template>
  <section class="login-page">
    <div class="box-login shadow">
      <div class="box-login__left">
        <div>
          <img :src="getImage({ image: config.logotipo.primary_white })" />
        </div>
      </div>

      <div class="box-login__right">
        <div class="title">
          {{ getTranslate({ text: "login.titles.register" }) }}
        </div>
        <form class="form">
          <div v-if="step === 0" class="forms">  
            <BaseInput
              name="name"
              :sufix="true"
              :value="name"
              :placeholder="getTranslate({ text: 'login.placeholders.name' })"
              color="#000"
              background="#fff"
              :error="checkError('name')"
              :error-message="errorMessage('name')"
              @update:value="(name = $event), removeError()"
            >
              <template v-slot:sufix>
                <img :src="getImage({ image: 'icons/black/person.png' })" />
              </template>
            </BaseInput>

            <BaseInput
              name="birthdate"
              :value="birthdate"
              :placeholder="getTranslate({ text: 'login.placeholders.birthdate' })"
              color="#000"
              background="#fff"
              :error="checkError('birthdate')"
              :error-message="errorMessage('birthdate')"
              @update:value="(birthdate = maskDateBR($event)), removeError()"
            />

            <BaseInput
              name="email"
              :sufix="true"
              :value="email"
              :placeholder="getTranslate({ text: 'login.placeholders.yout_email' })"
              color="#000"
              background="#fff"
              :error="checkError('email')"
              :error-message="errorMessage('email')"
              @update:value="(email = $event), removeError()"
            >
              <template v-slot:sufix>
                <img :src="getImage({ image: 'icons/black/e-mail.png' })" />
              </template>
            </BaseInput>
          </div>

          <div v-if="step === 1" class="forms">
            <BaseInput
              name="login"
              :value="login"
              :placeholder="getTranslate({ text: 'login.placeholders.nickname' })"
              color="#000"
              background="#fff"
              :error="checkError('login')"
              :error-message="errorMessage('login')"
              @update:value="(login = $event), removeError()"
            />
  
            <BaseInput
              name="password"
              :sufix="true"
              :sufix-click="true"
              :type="showPassword ? 'text' : 'password'"
              :value="password"
              :placeholder="getTranslate({ text: 'login.placeholders.password' })"
              color="#000"
              background="#fff"
              :error="checkError('password')"
              :error-message="errorMessage('password')"
              @update:value="(password = $event), removeError()"
              @click:sufix="togglePassword()"
            >
              <template v-slot:sufix>
                <img
                  :src="
                    getImage({
                      image: showPassword
                        ? 'icons/black/lock-open.png'
                        : 'icons/black/lock-closed.png',
                    })
                  "
                />
              </template>
            </BaseInput>
  
            <BaseInput
              name="confirmPassword"
              :sufix="true"
              :sufix-click="true"
              :type="showConfirmPassword ? 'text' : 'password'"
              :value="confirmPassword"
              :placeholder="getTranslate({ text: 'login.placeholders.confirm_password' })"
              color="#000"
              background="#fff"
              :error="checkError('confirmPassword')"
              :error-message="errorMessage('confirmPassword')"
              @update:value="(confirmPassword = $event), removeError()"
              @click:sufix="toggleConfirmPassword()"
            >
              <template v-slot:sufix>
                <img
                  :src="
                    getImage({
                      image: showConfirmPassword
                        ? 'icons/black/lock-open.png'
                        : 'icons/black/lock-closed.png',
                    })
                  "
                />
              </template>
            </BaseInput>
          </div>


          <div class="form__actions">
            <div v-if="step === 0" class="group-row">
              <div class="button-next" @click="nextStep()">
                {{ getTranslate({ text: "common.labels.advance" }) }}
              </div>
            </div>
            <div v-if="step === 1" class="group-row">
              <div class="button-before" @click="beforeStep()">
                {{ getTranslate({ text: "common.labels.back" }) }}
              </div>
              <div class="button-next" @click="sendRegister()">
                {{ getTranslate({ text: "common.labels.send" }) }}
              </div>
            </div>

            <div class="register no-select">
              <div>
                {{ getTranslate({ text: "login.labels.already_account" }) }}
              </div>
              <div class="bold" @click="goToLink('/login')">
                {{ getTranslate({ text: "login.titles.login" }) }}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
$box-radius: 0.625rem;
$box-height: 30rem;

.login-page::before {
  opacity: 1;
  transition: opacity 1s 0;
}

.login-page::after {
  opacity: 0.5;
  transition: opacity 1s ease;
}

.login-page {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background: var(--white-off);
  background-image: url("@/assets/images/banner1.jpg");
  background-size: cover;
  background-position: center;

  .title {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }

  .box-login {
    display: grid;
    grid-template-columns: 13.625rem auto;
    width: 38rem;
    height: $box-height;
    flex-direction: row;
    border-radius: $box-radius;

    &__left {
      display: flex;
      height: $box-height;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      background: #0d0f53d3;
      border-top-left-radius: $box-radius;
      border-bottom-left-radius: $box-radius;

      img {
        width: 10rem;
      }
    }

    &__right {
      display: flex;
      max-height: $box-height;
      overflow: auto;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-block: 30px;
      background: var(--white-opacity);
      border-top-right-radius: $box-radius;
      border-bottom-right-radius: $box-radius;
      gap: 1.5rem;

      .form {
        display: flex;
        width: calc(100% - 8rem);
        flex-direction: column;
        gap: 1rem;

        .forms {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        &__actions {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 1.5rem;
          margin-block-start: 0.5rem;

          .recover {
            cursor: pointer;
            font-size: 1rem;
            font-weight: bold;
          }

          .register {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            font-size: 1rem;
            margin-block-start: 20px;
          }
        }
      }
    }
  }
}

.group-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.button-before {
  cursor: pointer;
  user-select: none;
  padding-block: 8px;
  padding-inline: 15px;
  background: #e7e7e7;
  border-radius: 8px;
  font-weight: 700;

  &:hover {
    background: #cfcfcf;
  }
}

.button-next {
  cursor: pointer;
  user-select: none;
  padding-block: 8px;
  padding-inline: 15px;
  background: #F29826;
  border-radius: 8px;
  font-weight: 700;

  &:hover {
    background: #fda22a;
  }
}

@media screen and (max-width: 700px) {
  .login-page {
    align-items: center;
    justify-content: flex-start;

    .box-login {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;

      &__left {
        width: 100%;
        min-width: 100%;
        height: 13.625rem;
        min-height: 13.625rem;
        max-height: 13.625rem;
        flex-direction: row;
        border-radius: 0;
      }

      &__right {
        width: 100%;
        height: 100%;
        border-radius: 0;

        .form {
          width: 80%;
        }
      }
    }
  }
}
</style>
