import { get, post, del } from "../rest";
import { getLanguage } from "@/common/utils/texts";
import { getUser } from "@/common/services/users";

function getHeaders() {
  const user = getUser();
  return {
    language: getLanguage(),
    token: user?.token,
    "id": user?.id,
  };
}

export async function fetchAvatarById(file_id: string) {
  return await get(`common/avatar/${file_id}`, getHeaders());
}

export async function fetchCandidateImage(owner: string, size?: "large" | "small") {
  return await get(`admin/candidates/image/${owner}/${size || 'small'}`, getHeaders());
}

export async function addCandidateImage(owner: string, file: File) {
  return await post(`admin/candidates/image/${owner}`, { file }, getHeaders());
}

export async function delCandidateImage(owner: string) {
  return await del(`admin/candidates/image/${owner}`, getHeaders());
}
