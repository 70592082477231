import { get, post } from "../rest";
import { getLanguage } from "@/common/utils/texts";
import { getUser } from "@/common/services/users";
import { CredentialType } from "@/common/schemas";

function getHeaders() {
  const { token, id } = getUser() ?? {};

  return {
    "language": getLanguage(),
    "token": token,
    "id": id,
  };
}

export async function credentialAdd(member_id: string, data: CredentialType ) {
  return await post(`credential/${member_id}`, data, getHeaders());
}

export async function credentialGet(member_id: string) {
  return await get(`credential/list/${member_id}`, getHeaders());
}