export const login = {
    titles: {
      login: "Access Account",
      recover: "Recover Password",
      register: "Register",
      new_password: "New password",
    },
    labels: {
      recover: "Recover Password!",
      dont_account: "Dont account?",
      already_account: "Already accoun?",
    },
    placeholders: {
      nickname: "Login nickname",
      login: "Nickname or email",
      email: "Registered email",
      password: "Password",
      confirm_password: "Confirm password",
      yout_email: "Enter your email",
      name: "Full name",
      birthdate: "Birthdate",
    },
  };
  