import { get, post } from "../rest";
import { getLanguage } from "@/common/utils/texts";
import { getUser } from "@/common/services/users";

function getHeaders() {
  const { token, id } = getUser() ?? {};

  return {
    "content-type": "application/json",
    "language": getLanguage(),
    "token": token,
    "id": id,
  };
}

export async function questionsList(concurse_id: string) {
  return await get(`admin/questions/${concurse_id}`, getHeaders());
}

export async function questionsResponse(payload: any) {
  return await post("admin/questions/response", payload, getHeaders());
}