import { toast as Toast } from "vue3-toastify";

type PositionsEnum =
  | "top-left"
  | "top-center"
  | "top-right"
  | "bottom-left"
  | "bottom-center"
  | "bottom-right";

type TypesEnum =
  | "default"
  | "error"
  | "info"
  | "loading"
  | "success"
  | "warning";

export const toast = (data: {
  message: string;
  position?: PositionsEnum;
  type?: TypesEnum;
  close?: number;
}) => {
  Toast(data.message, {
    autoClose: data.close ?? 3000,
    position: data.position ?? "top-right",
    type: data.type ?? "default",
  });
};
