<script setup lang="ts">
import store from "@/store";
import { ref, onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useFetchCredential } from "@/common/hooks/credentials";
import { getDate, getHour } from "@/common/utils/dates";
import { divToImage, getImage } from "@/common/utils/images";
import { QrCode } from "@/components";

import type { CredentialType } from "@/common/schemas"

const { t, tm } = useI18n();

const { mutateAsync: refetch } = useFetchCredential();

const credentials = ref<CredentialType[]>([]);

const credentialTypes = computed(() => tm("credentials.types"));
const credentialStatus = computed(() => tm("credentials.status"));

const userQrcode = computed(() => (`https:/www.adm.animestars.com.br/credential/chech/${store.getters.user.id}`));
const showQrcode = ref(false);

const credentialType = ref("");

const imageTypes = {
    alley: getImage({ image: "images/credentials/alley.png" }),
    cosplay: getImage({ image: "images/credentials/cosplay.png" }),
    influencer: getImage({ image: "images/credentials/influencer.png" }),
    media: getImage({ image: "images/credentials/media.png" }),
    shopkeeper: getImage({ image: "images/credentials/shopkeeper.png" }),
    jurors: getImage({ image: "images/credentials/jurors.png" }),
};

const credentialsImages = computed(() => imageTypes[credentialType.value as never] || null);

const userAvatar = computed(() => {
    if (store.getters.avatar)
        return store.getters.avatar;

    return null;
})

const userName = computed(() => {
    const { first_name, last_name, social_name } = store.getters.profile || {};

    if (social_name)
        return social_name;

    if (first_name)
        return `${first_name} ${last_name}`;

    return "";
});

const userCompany = computed(() => {
    const { metadata } = store.getters.profile;

    if (metadata)
        return metadata["company_name"] || metadata["media_name"] || "";

    return "";
});

function fetchCredencial() {
    store.commit("SET_LOADING", true);
    refetch(store.getters.user.id, {
        onSuccess: (response) => {
            const { success, data } = response;

            if (success)
                credentials.value = data;
        }
    })
    .finally(() => {
        store.commit("SET_LOADING", false);
    })
}

function openCredential(data: CredentialType) {
    credentialType.value = data.type;
    showQrcode.value = true;
}

function download() {
    const div = document.getElementById("credential");
    if (!div) return;

    divToImage("cosplay-credential.jpg", div);
}

onMounted(() => {
    fetchCredencial();
});
</script>

<template>
    <section class="credentials-page">
        <h2 class="uppercase">
            {{ t("credentials.labels.my_credentials") }}
        </h2>
        <div class="list">
            <template v-for="(item, index) in credentials" :key="index">
                <div class="credential">
                    <div class="infos">
                        <div class="line">
                            <label>
                                {{ t("credentials.labels.event") }}:
                            </label>
                            <span>
                                ANIME STARS E1
                            </span>
                        </div>
                        <div class="line">
                            <label>
                                {{ t("credentials.labels.type") }}:
                            </label>
                            <span>
                                {{ credentialTypes[item.type]?.toUpperCase() }}
                            </span>
                        </div>
                        <div class="line">
                            <label>
                                {{ t("credentials.labels.date") }}:
                            </label>
                            <span>
                                {{ getDate(item.created) }}
                            </span>
                        </div>
                        <div class="line">
                            <label>
                                {{ t("credentials.labels.hour") }}:
                            </label>
                            <span>
                                {{ getHour(item.created) }}
                            </span>
                        </div>
                        <div class="line">
                            <label>
                                {{ t("credentials.labels.status") }}:
                            </label>
                            <span>
                                {{ credentialStatus[item.status]?.toUpperCase() }}
                            </span>
                        </div>
                    </div>
                    <div class="actions">
                        <div class="buttons" v-if="item.status !== 'disapproved'">
                            <img src="@/assets/icons/black/eye-open.png" @click="openCredential(item)" />
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="credentials.length === 0">
                <div class="credential">
                    {{ t("credentials.labels.empty") }}
                </div>
            </template>
        </div>
        <div v-if="showQrcode" class="credential-view">
            <div class="box">
                <div 
                    id="credential"
                    class="content"
                    :style="{ 'background-image': 'url(' + credentialsImages + ')' }"
                >
                    <div class="avatar">
                        <img :src="userAvatar" />
                    </div>
                    <div class="infos">
                        <span>
                            {{ userName }}
                        </span>
                        <span>
                            {{ userCompany }}
                        </span>
                    </div>
                    <div class="qrcode">
                        <QrCode :text="userQrcode" />
                    </div>
                </div>
                <div class="buttons">
                    <button @click="download()">
                        Download
                    </button>
                    <button @click="showQrcode = false">
                        Fechar
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.credentials-page {
    display: flex;
    min-height: 63vh;
    flex-direction: column;
    padding-block: 2rem;

    .list {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-block-start: 1rem;

        .credential {
            display: flex;
            flex-direction: row;
            padding: 15px;
            border-radius: 8px;
            border: 1px solid #ddd;

            .infos {
                display: flex;
                flex-direction: column;
                flex: 4;
                gap: 5px;

                .line {
                    display: grid;
                    gap: 8px;
                    grid-template-columns: 1fr 5fr;

                    label {
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                }
            }

            .actions {
                flex: 1;

                .buttons {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    gap: 10px;

                    img {
                        cursor: pointer;
                        width: 40px;
                        height: 40px;
                        opacity: 0.5;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.credential-view {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #0000007c;
    padding-block: 10%;

    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 420px;
        background: white;
        border-radius: 8px;
        padding-block: 10px;
        padding-inline: 5px;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        height: 711px;
        min-height: 711px;
        background-position: top left;
        background-size: 400px 711px;

        .avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            width: 200px;
            height: 170px;
            background: white;
            margin-block-start: 192px;

            img {
                width: 100%;
            }
        }

        .infos {
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 250px;
            height: 67px;
            color: #000;
            text-align: center;
            font-size: 17.5px;
            font-weight: bold;
            background: white;
            padding-block-start: 10px;
        }

        .qrcode {
            width: 120px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;
        margin-block: 1rem;
        gap: 20px;

        button {
            width: fit-content;
            height: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            img {
                width: 30px;
            }
        }
    }
}
</style>
