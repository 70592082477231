<script setup lang="ts">
import { computed, withDefaults } from "vue";
import { getImage } from "@/common/utils/images";

interface PropsType {
  title?: string;
  subtitle?: string;
  image?: string | null;
  size?: string;
  type?: "column" | "row";
  background?: string;
  loading?: boolean;
}

const props = withDefaults(defineProps<PropsType>(), {
  image: null,
  size: "70px",
  type: "row",
  background: "#c9c9c9",
  loading: false,
});

const imageData = computed(() =>
  props.image ? props.image : getImage({ image: "icons/black/person.png" })
);
</script>

<template>
  <section class="base-avatar" :class="[type]">
    <div class="avatar">
      <img v-if="!loading" :src="imageData" alt="avatar" />
      <img
        v-else
        :src="getImage({ image: 'images/loading.gif' })"
        class="avatar-loading"
      />
    </div>
    <div v-if="title || subtitle" class="infos">
      <span v-if="title" class="title">{{ title }}</span>
      <span v-if="subtitle" class="subtitle">{{ subtitle }}</span>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.base-avatar {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  .avatar {
    display: flex;
    width: v-bind("size");
    height: v-bind("size");
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: v-bind("background");
    border-radius: calc(v-bind("size") / 2);
    overflow: hidden;

    img {
      width: auto;
      height: v-bind("size");
    }

    .avatar-loading {
      width: 50%;
    }
  }

  .infos {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .title {
      font-size: 1rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    .subtitle {
      font-size: 0.875rem;
    }
  }
}
</style>
