<script setup lang="ts">
import QRCodeVue3 from "qrcode-vue3";

export interface OptionsQRCodeType {
    width: number;
    height: number;
    colorDark: string;
    colorLight: string;
}

defineProps<{
    text?: string;
}>();

</script>

<template>
    <div>
        <QRCodeVue3 :value="text" :width="200" :height="200"
            :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
            :backgroundOptions="{ color: '#ffffff' }" :cornersSquareOptions="{ type: 'dot', color: '#000000' }"
            :cornersDotOptions="{ type: undefined, color: '#000000' }" :dotsOptions="{
            type: 'square',
            color: '#000000',
        }" />
    </div>
</template>