<script setup lang="ts">
import store from "@/store";
import { ref, computed, onBeforeMount, watchEffect, onMounted } from "vue";
import { config } from "./config";
import { useCheckToken, useUserProfile } from "@/common/hooks/users";
import { userFetchAvatar } from "@/common/api/rest/users";
import { getImage } from "./common/utils/images";
import { getTranslate } from "./common/utils/texts";
import { setTitle, setFavIcon } from "./common/utils/common";
import { getUser, setUser, logOutUser, setProfile } from "@/common/services/users";
import { AppBar, AsideMenu, BaseHeader, BaseFooter } from "./components";
import { toast } from "@/common/utils/toast";
import { checkRouter } from "@/common/utils/link";

const {
  data: logged,
  isLoading: loadingToken,
  refetch: fetchToken,
} = useCheckToken();

const { mutateAsync: fetchProfile } = useUserProfile();

const user = ref();
const isMobile = ref(false);
const isLoading = computed(() => loadingToken.value);

const menuItems = computed(() => [
  {
    url: "/home",
    label: getTranslate({ text: "common.labels.home" })?.toUpperCase(),
    icon: getImage({ image: "icons/white/home-stoned.png" }),
    iconActive: getImage({ image: "icons/black/home-stoned.png" }),
  },
  {
    url: "/profile",
    label: getTranslate({ text: "common.labels.my_account" })?.toUpperCase(),
    icon: getImage({ image: "icons/white/shield.png" }),
    iconActive: getImage({ image: "icons/black/shield.png" }),
  },
  {
    url: "/credentials",
    label: getTranslate({ text: "common.labels.credentials" })?.toUpperCase(),
    icon: getImage({ image: "icons/white/ticket.png" }),
    iconActive: getImage({ image: "icons/black/ticket.png" }),
  },
  {
    url: "/concurses",
    label: getTranslate({ text: "common.labels.concurses" })?.toUpperCase(),
    icon: getImage({ image: "icons/white/trophy.png" }),
    iconActive: getImage({ image: "icons/black/trophy.png" }),
  },
]);

watchEffect(() => {
  store.commit("SET_MOBILE", isMobile.value);
  store.commit("SET_LOADING", isLoading.value);
});

function fetchAvatar(id: string) {
  userFetchAvatar(id)
    .then((response) => {
      if (response.success) {
        store.commit("SET_AVATAR", response.data.file);
      }
    });
}

function getProfile() {
  fetchProfile(user.value.id)
    .then(async (result) => {
      const { success, data } = result;
      if (success) {
        fetchAvatar(data.id);
        setProfile({
          ...data.profile,
          jurors: data.jurors,
        });
      }
    })
    .catch(() => {
      toast({ message: "Erro ao carregar o perfil", type: "error" });
    });
}

onBeforeMount(() => {
  setTitle(config.body.title);
  setFavIcon(getImage({ image: config.logotipo.icon }));
  store.commit("SET_MOBILE", window.innerWidth < 800);
  isMobile.value = window.innerWidth < 800;

  const userData = getUser();
  if (userData) {
    user.value = userData;
    setUser(userData);
    getProfile();
  }
});

onMounted(() => {
  fetchToken().then(response => {
    if (!response.data) {
      logOutUser();
    }
  });

  window.addEventListener("resize", () => {
    isMobile.value = window.innerWidth < 800;
  });
});
</script>

<template>
  <AppBar v-if="$store.getters.logged && isMobile" />
  <AsideMenu v-if="$store.getters.logged && !isMobile" :menu-items="menuItems" background-color="#0D0F53"
    menu-color="transparent" menu-color-active="#ffcc15" font-color="white" font-color-active="black"
    :logo="getImage({ image: config.logotipo.primary, is_local: true })"
    :logo-alt="getTranslate({ text: 'accessibility.images.logotipo' })" />
  <div class="pages" :class="{ full: !$store.getters.logged }">
    <BaseHeader v-if="$store.getters.logged" />
    <main>
      <router-view />
    </main>
    <BaseFooter v-if="$store.getters.logged" :year="config.company.year" :name="config.company.name" />
  </div>
  <div v-if="$store.getters.loading" class="loading" />
</template>

<style lang="scss" scoped>
#app {
  display: flex;
  flex-direction: row;

  .pages {
    display: flex;
    margin-inline-start: 15rem;
    flex-direction: column;
    overflow: auto;
    flex: 1;

    main {
      display: flex;
      flex-direction: column;
      padding-inline: var(--padding-inline);
    }
  }

  .pages.full {
    width: 100%;
    // height: auto;
    margin-inline-start: 0;

    main {
      display: block;
      padding-inline: 0;
    }
  }
}

@media screen and (max-width: 800px) {
  #app {
    flex-direction: column;

    .pages {
      margin-inline-start: 0;
    }
  }
}
</style>
